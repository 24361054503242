/**
 * @generated SignedSource<<44214b3e67ee50615a064741fa2f28f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthorizationUtilsQuery$variables = {};
export type AuthorizationUtilsQuery$data = {
  readonly profile: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"AuthorizationUtilsUser">;
    };
  };
};
export type AuthorizationUtilsQuery = {
  response: AuthorizationUtilsQuery$data;
  variables: AuthorizationUtilsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "role",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Supplier",
    "kind": "LinkedField",
    "name": "supplier",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "language",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Customer",
    "kind": "LinkedField",
    "name": "customer",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorizationUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "AuthorizationUtilsUser",
                "selections": (v1/*: any*/),
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthorizationUtilsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserPayload",
        "kind": "LinkedField",
        "name": "profile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ad6e399f9d85b07ef97d313c58d34a9",
    "id": null,
    "metadata": {},
    "name": "AuthorizationUtilsQuery",
    "operationKind": "query",
    "text": "query AuthorizationUtilsQuery {\n  profile {\n    user {\n      ...AuthorizationUtilsUser\n      id\n    }\n  }\n}\n\nfragment AuthorizationUtilsUser on User {\n  id\n  email\n  role\n  supplier {\n    id\n    name\n    currency\n    language\n  }\n  customer {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef0685e98dc1f4589ebeca30019a7be3";

export default node;
