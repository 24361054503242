import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { List, Text, useTheme } from 'react-native-paper';
import { Link } from 'react-native-ridge-navigation';
import { graphql } from 'react-relay';
import { useFragment } from 'react-relay/hooks';

import { ShipmentPortalStatusLocally } from './ShipmentListPortalScreen';
import routes from '../NavigatorRoutes';
import { ShipmentListPortalItem$key } from '../__generated__/ShipmentListPortalItem.graphql';
import { dateFromUnix, getFriendlyDateIntn } from '../utils';

interface ShipmentListPortalItemTypes {
  itemRef: ShipmentListPortalItem$key;
}

function ShipmentListPortalItem({ itemRef }: ShipmentListPortalItemTypes) {
  const theme = useTheme();

  const item = useFragment<ShipmentListPortalItem$key>(
    graphql`
      fragment ShipmentListPortalItem on ShipmentWithStatus {
        id
        supplierName
        transportationPrice
        customerName
        shipmentDate
        hasDifferentCustomerAddress
        hasDifferentSupplierAddress
        supplierCustomAddress
        supplierCustomPostalcode
        supplierCustomCity
        customerCustomAddress
        customerCustomPostalcode
        customerCustomCity
        customerCustomName
        supplierCustomName
        customerCustomCountry
        supplierCustomCountry
        expectedDeliveryDate
        weight
        licensePlate
        portalStatus
      }
    `,
    itemRef,
  );

  const customerName = item.hasDifferentCustomerAddress
    ? item.customerCustomName
    : item.customerName;
  const supplierName = item.hasDifferentSupplierAddress
    ? item.supplierCustomName
    : item.supplierName;

  if (!item) {
    return null;
  }

  return (
    <Link to={routes.ShipmentDetailPortalScreen} params={{ id: item.id }}>
      {(linkProps) => (
        <List.Item
          {...linkProps}
          // onPress={() => {}}
          title={() => (
            <View style={{ flexDirection: 'row' }}>
              <Text>
                {[
                  supplierName,
                  customerName,
                  getFriendlyDateIntn(dateFromUnix(item.shipmentDate || 0)),
                ]
                  .filter(Boolean)
                  .join(' | ')}
              </Text>
              {item?.licensePlate?.length > 0 ? (
                <Text
                  style={styles.licensePlate}
                  numberOfLines={1}
                  variant="titleSmall"
                >
                  {item.licensePlate}
                </Text>
              ) : null}
            </View>
          )}
          description={
            item.portalStatus === ShipmentPortalStatusLocally.UPDATE_DETAILS
              ? 'View details'
              : item.portalStatus === ShipmentPortalStatusLocally.PROPOSED
              ? 'View proposal'
              : 'Submit your proposal'
          }
          right={() => {
            const { backgroundColor, color } = getBackgroundColorBasedOnStatus(
              item.portalStatus as ShipmentPortalStatusLocally,
            );
            return (
              <View style={styles.rightContainer} pointerEvents="auto">
                <View
                  style={{
                    alignSelf: 'flex-start',
                    paddingHorizontal: 10,
                    paddingVertical: 6,
                    backgroundColor,
                    borderRadius: theme.roundness,
                  }}
                >
                  <Text style={{ color }}>
                    {statusToLabel(
                      item.portalStatus as ShipmentPortalStatusLocally,
                    )}
                  </Text>
                </View>
              </View>
            );
          }}
        />
      )}
    </Link>
  );
}

const styles = StyleSheet.create({
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  licensePlate: {
    backgroundColor: 'rgb(247, 183, 47)',
    padding: 2,
    paddingLeft: 6,
    paddingRight: 6,
    // borderWidth: 2,
    // borderColor: '#525252',
    borderRadius: 5,
    marginRight: 6,
    marginLeft: 6,
    fontSize: 12,
    textAlign: 'center',
    color: '#000',
    minWidth: 80,
  },
});

export default React.memo(ShipmentListPortalItem);

function getBackgroundColorBasedOnStatus(status: ShipmentPortalStatusLocally) {
  switch (status) {
    case ShipmentPortalStatusLocally.OPEN:
      return { backgroundColor: '#0065ff', color: 'white' };
    case ShipmentPortalStatusLocally.UPDATE_DETAILS:
      return { backgroundColor: 'orange', color: 'white' };
    case ShipmentPortalStatusLocally.PROPOSED:
      return { backgroundColor: 'orange', color: 'white' };
    default:
      return { backgroundColor: 'orange', color: 'white' };
  }
}

function statusToLabel(status: ShipmentPortalStatusLocally) {
  switch (status) {
    case ShipmentPortalStatusLocally.OPEN:
      return 'Open';
    case ShipmentPortalStatusLocally.UPDATE_DETAILS:
      return 'Update details';
    case ShipmentPortalStatusLocally.PROPOSED:
      return 'Proposed';
    default:
      return 'Unknown';
  }
}
