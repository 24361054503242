import { PortalProvider } from '@gorhom/portal';
import React from 'react';
import { View } from 'react-native';
import { Text } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import ErrorBoundaryWithRetry from './ErrorBoundaryWithRetry';

const Fallback = React.memo(() => {
  return (
    <>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text>Laden...</Text>
      </View>
    </>
  );
});

export default function AsyncBoundaryScreen({ children }: { children: any }) {
  return (
    <SafeAreaProvider>
      <ErrorBoundaryWithRetry>
        <React.Suspense fallback={<Fallback />}>
          <PortalProvider>{children}</PortalProvider>
        </React.Suspense>
      </ErrorBoundaryWithRetry>
    </SafeAreaProvider>
  );
}
