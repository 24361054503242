/**
 * @generated SignedSource<<a5e9f76bac75636a457b3b20bbd21777>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type UserRole = "ADMIN" | "CUSTOMER" | "TRANSPORTER";
import { FragmentRefs } from "relay-runtime";
export type AuthorizationUtilsUser$data = {
  readonly customer: {
    readonly id: string;
  } | null;
  readonly email: string;
  readonly id: string;
  readonly role: UserRole;
  readonly supplier: {
    readonly currency: string;
    readonly id: string;
    readonly language: string;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "AuthorizationUtilsUser";
};
export type AuthorizationUtilsUser$key = {
  readonly " $data"?: AuthorizationUtilsUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuthorizationUtilsUser">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AuthorizationUtilsUser"
};

(node as any).hash = "a70bbfad72ba2d842c96ad68eda36354";

export default node;
