/**
 * @generated SignedSource<<793d7b78d44105364f769db91f6b4ae8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConversationDetailScreenQuery$variables = {
  id: string;
};
export type ConversationDetailScreenQuery$data = {
  readonly conversation: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ConversationDetailFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"LabelsFragment">;
};
export type ConversationDetailScreenQuery = {
  response: ConversationDetailScreenQuery$data;
  variables: ConversationDetailScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "search",
        "value": null
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 2000
  },
  {
    "kind": "Literal",
    "name": "ordering",
    "value": [
      {
        "direction": "ASC",
        "sort": "ID"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConversationDetailScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Conversation",
        "kind": "LinkedField",
        "name": "conversation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConversationDetailFragment"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LabelsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConversationDetailScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Conversation",
        "kind": "LinkedField",
        "name": "conversation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "note",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "answered",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ConversationLabel",
            "kind": "LinkedField",
            "name": "conversationLabels",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "label",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "LabelConnection",
        "kind": "LinkedField",
        "name": "labels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LabelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": "labels(filter:{\"search\":null},first:2000,ordering:[{\"direction\":\"ASC\",\"sort\":\"ID\"}])"
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "ordering",
          "filter"
        ],
        "handle": "connection",
        "key": "LabelsScreen_labels",
        "kind": "LinkedHandle",
        "name": "labels"
      }
    ]
  },
  "params": {
    "cacheID": "ef3c82ed589dc200489038934b50daa0",
    "id": null,
    "metadata": {},
    "name": "ConversationDetailScreenQuery",
    "operationKind": "query",
    "text": "query ConversationDetailScreenQuery(\n  $id: ID!\n) {\n  conversation(id: $id) {\n    id\n    ...ConversationDetailFragment\n  }\n  ...LabelsFragment\n}\n\nfragment ConversationDetailFragment on Conversation {\n  id\n  note\n  answered\n  ...ConversationLabels\n  ...ConversationFormType\n}\n\nfragment ConversationFormType on Conversation {\n  note\n  answered\n  status\n  date\n  type\n  contact {\n    id\n  }\n}\n\nfragment ConversationLabels on Conversation {\n  id\n  conversationLabels {\n    id\n    label {\n      id\n      name\n    }\n  }\n}\n\nfragment Label on Label {\n  id\n  name\n}\n\nfragment LabelsFragment on Query {\n  labels(first: 2000, ordering: [{sort: ID, direction: ASC}], filter: {}) {\n    edges {\n      node {\n        id\n        ...Label\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "896942546a3555ba0ad7025f0109ffa5";

export default node;
