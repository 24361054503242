import { Image } from 'expo-image';
import * as React from 'react';
import { StyleSheet } from 'react-native';
import { TouchableRipple, useTheme } from 'react-native-paper';
import { BottomTabLink } from 'react-native-ridge-navigation';

import { BottomRoots } from './Navigator';
import iconDark from './img/icon-dark.png';
import icon from './img/icon.png';
import logoDark from './img/logo-dark.png';
import logo from './img/logo.png';
import { useWindowSize } from './useWindowSize';

export default function BottomRootStart({
  orientation,
}: {
  orientation: 'horizontal' | 'vertical';
}) {
  const theme = useTheme();
  const { width } = useWindowSize();
  const isBigScreen = width > 1240;
  const isDark = theme.dark;
  if (orientation === 'vertical') {
    return null;
  }
  return (
    <BottomTabLink to={BottomRoots.Shipment} params={{}}>
      {(props) => (
        <TouchableRipple
          {...props}
          style={[
            styles.root,
            {
              width: isBigScreen ? 284 : 77,
              padding: isBigScreen ? 24 : 6,
              borderRadius: theme.roundness,
            },
          ]}
          onPress={() => {}}
        >
          <Image
            style={isBigScreen ? styles.ddLogo : styles.ddIcon}
            //@ts-ignore
            source={isBigScreen ? (isDark ? logoDark : logo) : icon}
          />
        </TouchableRipple>
      )}
    </BottomTabLink>
  );
}

const styles = StyleSheet.create({
  root: {
    alignSelf: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  ddIcon: { width: '100%', height: 85 },
  ddLogo: { width: '70%', height: 99 },
});
