/**
 * @generated SignedSource<<efec24c624467a11570f742919905bb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OutstandingInvoicesScreenQuery$variables = {
  search?: string | null;
};
export type OutstandingInvoicesScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OutstandingInvoicesScreenFragment">;
};
export type OutstandingInvoicesScreenQuery = {
  response: OutstandingInvoicesScreenQuery$data;
  variables: OutstandingInvoicesScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v2 = {
  "id": {
    "notNull": true
  }
},
v3 = {
  "equalTo": "PENDING"
},
v4 = {
  "moreThan": 0
},
v5 = [
  {
    "fields": [
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "article": (v2/*: any*/),
          "customer": (v2/*: any*/),
          "customerInvoiceStatus": (v3/*: any*/),
          "or": {
            "article": (v2/*: any*/),
            "customer": (v2/*: any*/),
            "customerInvoiceStatus": (v3/*: any*/),
            "customerWeight": (v4/*: any*/),
            "sellingPrice": (v4/*: any*/)
          },
          "sellingPrice": (v4/*: any*/),
          "weight": (v4/*: any*/)
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "ordering",
    "value": [
      {
        "direction": "DESC",
        "sort": "ID"
      }
    ]
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OutstandingInvoicesScreenQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "OutstandingInvoicesScreenFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OutstandingInvoicesScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ShipmentConnection",
        "kind": "LinkedField",
        "name": "shipments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShipmentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Shipment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Customer",
                    "kind": "LinkedField",
                    "name": "customer",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sellingPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weight",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerWeight",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Article",
                    "kind": "LinkedField",
                    "name": "article",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerInvoiceStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "ordering",
          "filter"
        ],
        "handle": "connection",
        "key": "OutstandingInvoiceScreen_shipments",
        "kind": "LinkedHandle",
        "name": "shipments"
      }
    ]
  },
  "params": {
    "cacheID": "e72d73561c6dc42e1465fd21aefcb598",
    "id": null,
    "metadata": {},
    "name": "OutstandingInvoicesScreenQuery",
    "operationKind": "query",
    "text": "query OutstandingInvoicesScreenQuery(\n  $search: String\n) {\n  ...OutstandingInvoicesScreenFragment_40zwac\n}\n\nfragment OutstandingInvoicesScreenFragment_40zwac on Query {\n  shipments(first: 20, ordering: [{sort: ID, direction: DESC}], filter: {search: $search, where: {article: {id: {notNull: true}}, customer: {id: {notNull: true}}, customerInvoiceStatus: {equalTo: PENDING}, weight: {moreThan: 0}, sellingPrice: {moreThan: 0}, or: {article: {id: {notNull: true}}, customer: {id: {notNull: true}}, customerInvoiceStatus: {equalTo: PENDING}, customerWeight: {moreThan: 0}, sellingPrice: {moreThan: 0}}}}) {\n    edges {\n      cursor\n      node {\n        id\n        ...OutstandingInvoicesScreenItem\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment OutstandingInvoicesScreenItem on Shipment {\n  id\n  customer {\n    name\n    currency\n    id\n  }\n  sellingPrice\n  weight\n  customerWeight\n  article {\n    name\n    id\n  }\n  customerInvoiceStatus\n}\n"
  }
};
})();

(node as any).hash = "8e4460239937ed2f57f4cc71ed2c39de";

export default node;
