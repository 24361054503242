const defaultFontVariants = ['bodyLarge', 'bodyMedium', 'bodySmall', 'default'];

const defaultBoldFontVariants = [
  'labelLarge',
  'labelMedium',
  'labelSmall',
  'titleMedium',
  'titleSmall',
  'titleLarge',
];

const titleFontVariants = [
  'displayLarge',
  'displayMedium',
  'displaySmall',

  'headlineLarge',
  'headlineMedium',
  'headlineSmall',
];

export const fontConfig: any = {};
defaultFontVariants.forEach((variant) => {
  fontConfig[variant] = {
    fontFamily: 'Montserrat_500Medium',
  };
});
defaultBoldFontVariants.forEach((variant) => {
  fontConfig[variant] = {
    fontFamily: 'Montserrat_600SemiBold',
  };
});
titleFontVariants.forEach((variant) => {
  fontConfig[variant] = {
    fontFamily: 'Montserrat_600SemiBold',
    // letterSpacing: 0.3,
  };
});
