/**
 * @generated SignedSource<<07e9b7362cf96beb3d4b119cfd343b9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShipmentListPortalItem$data = {
  readonly customerCustomAddress: string | null;
  readonly customerCustomCity: string | null;
  readonly customerCustomCountry: string | null;
  readonly customerCustomName: string | null;
  readonly customerCustomPostalcode: string | null;
  readonly customerName: string | null;
  readonly expectedDeliveryDate: number | null;
  readonly hasDifferentCustomerAddress: boolean;
  readonly hasDifferentSupplierAddress: boolean;
  readonly id: string;
  readonly licensePlate: string | null;
  readonly portalStatus: string;
  readonly shipmentDate: number | null;
  readonly supplierCustomAddress: string | null;
  readonly supplierCustomCity: string | null;
  readonly supplierCustomCountry: string | null;
  readonly supplierCustomName: string | null;
  readonly supplierCustomPostalcode: string | null;
  readonly supplierName: string | null;
  readonly transportationPrice: number | null;
  readonly weight: number | null;
  readonly " $fragmentType": "ShipmentListPortalItem";
};
export type ShipmentListPortalItem$key = {
  readonly " $data"?: ShipmentListPortalItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShipmentListPortalItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShipmentListPortalItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplierName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transportationPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipmentDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasDifferentCustomerAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasDifferentSupplierAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplierCustomAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplierCustomPostalcode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplierCustomCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerCustomAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerCustomPostalcode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerCustomCity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerCustomName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplierCustomName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerCustomCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplierCustomCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expectedDeliveryDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "licensePlate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portalStatus",
      "storageKey": null
    }
  ],
  "type": "ShipmentWithStatus",
  "abstractKey": null
};

(node as any).hash = "1ca8f2904fff27385166fea2d89bcc34";

export default node;
