/**
 * @generated SignedSource<<bc8f57c024b1b2898fb1b0476fc51778>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoicesScreenQuery$variables = {
  search?: string | null;
};
export type InvoicesScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConfigInterestRateFragment" | "InvoicesScreenFragment">;
};
export type InvoicesScreenQuery = {
  response: InvoicesScreenQuery$data;
  variables: InvoicesScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v2 = [
  {
    "fields": [
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "where",
        "value": {
          "openInterest": {
            "moreThan": 0
          }
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "ordering",
    "value": [
      {
        "direction": "DESC",
        "sort": "ID"
      }
    ]
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicesScreenQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "InvoicesScreenFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ConfigInterestRateFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvoicesScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CustomerWithDueAmountConnection",
        "kind": "LinkedField",
        "name": "customerWithDueAmounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerWithDueAmountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerWithDueAmount",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "openInterest",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "ordering",
          "filter"
        ],
        "handle": "connection",
        "key": "InvoiceScreen_customerWithDueAmounts",
        "kind": "LinkedHandle",
        "name": "customerWithDueAmounts"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "id",
            "value": "configInterestRate-1"
          }
        ],
        "concreteType": "ConfigInterestRate",
        "kind": "LinkedField",
        "name": "configInterestRate",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "interestRate",
            "storageKey": null
          }
        ],
        "storageKey": "configInterestRate(id:\"configInterestRate-1\")"
      }
    ]
  },
  "params": {
    "cacheID": "04f37f63a36e2824894413b457d28f7d",
    "id": null,
    "metadata": {},
    "name": "InvoicesScreenQuery",
    "operationKind": "query",
    "text": "query InvoicesScreenQuery(\n  $search: String\n) {\n  ...InvoicesScreenFragment_40zwac\n  ...ConfigInterestRateFragment\n}\n\nfragment ConfigInterestRateFragment on Query {\n  configInterestRate(id: \"configInterestRate-1\") {\n    id\n    interestRate\n  }\n}\n\nfragment InvoicesScreenFragment_40zwac on Query {\n  customerWithDueAmounts(first: 20, ordering: [{sort: ID, direction: DESC}], filter: {search: $search, where: {openInterest: {moreThan: 0}}}) {\n    edges {\n      cursor\n      node {\n        id\n        ...InvoicesScreenItem\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ...ConfigInterestRateFragment\n}\n\nfragment InvoicesScreenItem on CustomerWithDueAmount {\n  id\n  name\n  currency\n  remainingAmount\n  openInterest\n}\n"
  }
};
})();

(node as any).hash = "af9b871e11bf249a1e166270cb3ceff3";

export default node;
