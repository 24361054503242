/**
 * @generated SignedSource<<0fab74cd6f7b4ca3e027a3888032dbb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactDetailScreenQuery$variables = {
  id: string;
};
export type ContactDetailScreenQuery$data = {
  readonly contact: {
    readonly id: string;
    readonly telephone: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"ContactDetailFragment" | "ContactDetailScreenAppbarFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ConversationsFragment" | "LabelsFragment">;
};
export type ContactDetailScreenQuery = {
  response: ContactDetailScreenQuery$data;
  variables: ContactDetailScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "telephone",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Label",
  "kind": "LinkedField",
  "name": "label",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "search",
  "value": null
},
v7 = [
  {
    "fields": [
      (v6/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 2000
  },
  {
    "kind": "Literal",
    "name": "ordering",
    "value": [
      {
        "direction": "ASC",
        "sort": "ID"
      }
    ]
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v12 = [
  "ordering",
  "filter"
],
v13 = [
  {
    "fields": [
      (v6/*: any*/),
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "equalTo",
                    "variableName": "id"
                  }
                ],
                "kind": "ObjectValue",
                "name": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "contact"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "ordering",
    "value": [
      {
        "direction": "DESC",
        "sort": "DATE"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactDetailScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Contact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContactDetailFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContactDetailScreenAppbarFragment"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "LabelsFragment"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "contactId",
            "variableName": "id"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ConversationsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactDetailScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Contact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Conversation",
            "kind": "LinkedField",
            "name": "conversations",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zipCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactLabel",
            "kind": "LinkedField",
            "name": "contactLabels",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "LabelConnection",
        "kind": "LinkedField",
        "name": "labels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LabelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": "labels(filter:{\"search\":null},first:2000,ordering:[{\"direction\":\"ASC\",\"sort\":\"ID\"}])"
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "filters": (v12/*: any*/),
        "handle": "connection",
        "key": "LabelsScreen_labels",
        "kind": "LinkedHandle",
        "name": "labels"
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "ConversationConnection",
        "kind": "LinkedField",
        "name": "conversations",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ConversationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Conversation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answered",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ConversationLabel",
                    "kind": "LinkedField",
                    "name": "conversationLabels",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "filters": (v12/*: any*/),
        "handle": "connection",
        "key": "ConversationsScreen_conversations",
        "kind": "LinkedHandle",
        "name": "conversations"
      }
    ]
  },
  "params": {
    "cacheID": "d9e165308625948fc926e2bc01342557",
    "id": null,
    "metadata": {},
    "name": "ContactDetailScreenQuery",
    "operationKind": "query",
    "text": "query ContactDetailScreenQuery(\n  $id: ID!\n) {\n  contact(id: $id) {\n    id\n    telephone\n    ...ContactDetailFragment\n    ...ContactDetailScreenAppbarFragment\n  }\n  ...LabelsFragment\n  ...ConversationsFragment_1zGCbo\n}\n\nfragment ContactDetailFragment on Contact {\n  id\n  telephone\n  conversations {\n    id\n  }\n  ...ContactFormType\n  ...ContactLabels\n}\n\nfragment ContactDetailScreenAppbarFragment on Contact {\n  id\n  name\n  telephone\n}\n\nfragment ContactFormType on Contact {\n  name\n  address\n  zipCode\n  city\n  telephone\n  level\n  country\n}\n\nfragment ContactLabels on Contact {\n  id\n  contactLabels {\n    id\n    label {\n      id\n      name\n    }\n  }\n}\n\nfragment ConversationItem on Conversation {\n  id\n  note\n  date\n  type\n  answered\n  status\n  conversationLabels {\n    label {\n      id\n      name\n    }\n    id\n  }\n}\n\nfragment ConversationsFragment_1zGCbo on Query {\n  conversations(first: 100, ordering: [{sort: DATE, direction: DESC}], filter: {where: {contact: {id: {equalTo: $id}}}}) {\n    edges {\n      node {\n        id\n        ...ConversationItem\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Label on Label {\n  id\n  name\n}\n\nfragment LabelsFragment on Query {\n  labels(first: 2000, ordering: [{sort: ID, direction: ASC}], filter: {}) {\n    edges {\n      node {\n        id\n        ...Label\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7345b45f0fe51ab063f5588442cabb4";

export default node;
