import { createScreens } from 'react-native-ridge-navigation';

import routes from './NavigatorRoutes';

export const BottomRoots = {
  Shipment: {
    path: '/shipment',
    title: () => 'Vrachten',
    icon: require('./img/truck-outline/icon-20.png'),
    selectedIcon: require('./img/truck/icon-20.png'),
    child: routes.ShipmentsScreen,
  },
  IncomingInvoice: {
    path: '/incoming-invoice',
    title: () => 'Inkoop',
    icon: require('./img/buy-outline/icon-20.png'),
    selectedIcon: require('./img/buy/icon-20.png'),
    child: routes.IncomingInvoicesScreen,
  },
  OutstandingInvoice: {
    path: '/outstanding-invoice',
    title: () => 'Verkoop',
    icon: require('./img/sell-outline/icon-20.png'),
    selectedIcon: require('./img/sell/icon-20.png'),
    child: routes.OutstandingInvoicesScreen,
  },
  Contact: {
    path: '/crm',
    title: () => 'CRM',
    icon: require('./img/interest-outline/icon-20.png'),
    selectedIcon: require('./img/interest/icon-20.png'),
    child: routes.ContactListScreen,
  },
  More: {
    path: '/more',
    title: () => 'Meer',
    icon: require('./img/more/icon-20.png'),
    selectedIcon: require('./img/more/icon-20.png'),
    child: routes.MoreScreen,
  },
};

export const screens = createScreens(routes);
