import { FlashList } from '@shopify/flash-list';
import { useNavigated } from 'navigation-react-mobile';
import * as React from 'react';
import { useState } from 'react';
import { View } from 'react-native';
import { Chip, IconButton } from 'react-native-paper';
import { useFocus, usePreloadResult } from 'react-native-ridge-navigation';
import { SafeAreaView } from 'react-native-safe-area-context';
import { graphql } from 'react-relay';
import { usePaginationFragment, usePreloadedQuery } from 'react-relay/hooks';

import ShipmentListPortalItem from './ShipmentListPortalItem';
import { logout } from '../Auth/Authorization';
import routes from '../NavigatorRoutes';
import { ShipmentListPortalScreenFragment$key } from '../__generated__/ShipmentListPortalScreenFragment.graphql';
import {
  ShipmentListPortalScreenQuery,
  ShipmentWithStatusWhere,
} from '../__generated__/ShipmentListPortalScreenQuery.graphql';
import {
  defaultStyles,
  withoutEmptyEdges,
  useEffectAfterMount,
} from '../utils';

export enum ShipmentPortalStatusLocally {
  OPEN = 'OPEN',
  PROPOSED = 'PROPOSED',
  UPDATE_DETAILS = 'UPDATE_DETAILS',
}

export function getShipmentListPortalWhere(state: ShipmentPortalStatusLocally) {
  switch (state) {
    case ShipmentPortalStatusLocally.PROPOSED:
      return {
        portalStatus: { equalTo: ShipmentPortalStatusLocally.PROPOSED },
      };
    case ShipmentPortalStatusLocally.UPDATE_DETAILS:
      return {
        portalStatus: { equalTo: ShipmentPortalStatusLocally.UPDATE_DETAILS },
      };
    default:
      return {
        portalStatus: { equalTo: ShipmentPortalStatusLocally.OPEN },
      };
  }
}

function ShipmentListPortalScreen() {
  const queryReference = usePreloadResult(routes.ShipmentListPortalScreen);
  const preloadedQuery = usePreloadedQuery<ShipmentListPortalScreenQuery>(
    graphql`
      query ShipmentListPortalScreenQuery(
        $search: String
        $where: ShipmentWithStatusWhere
      ) {
        ...ShipmentListPortalScreenFragment
          @arguments(search: $search, where: $where)
      }
    `,
    queryReference,
  );

  const { data, loadNext, hasNext, refetch } = usePaginationFragment<
    ShipmentListPortalScreenQuery,
    ShipmentListPortalScreenFragment$key
  >(
    graphql`
      fragment ShipmentListPortalScreenFragment on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 20 }
        after: { type: "String", defaultValue: null }
        search: { type: "String", defaultValue: null }
        where: { type: "ShipmentWithStatusWhere", defaultValue: null }
      )
      @refetchable(queryName: "ShipmentListPortalScreenPaginationQuery") {
        customShipments(
          first: $first
          after: $after
          ordering: [{ sort: ID, direction: DESC }]
          filter: { search: $search, where: $where }
        ) @connection(key: "ShipmentListPortalScreen_customShipments") {
          __id
          edges {
            cursor
            node {
              id
              ...ShipmentListPortalItem
            }
          }
        }
      }
    `,
    preloadedQuery,
  );

  const [currentStatus, setCurrentStatus] =
    useState<ShipmentPortalStatusLocally>(ShipmentPortalStatusLocally.OPEN);

  const refresh = React.useCallback(
    () =>
      refetch(
        {
          where: getShipmentListPortalWhere(currentStatus),
        },
        { fetchPolicy: 'store-and-network' },
      ),
    [refetch, currentStatus],
  );

  useEffectAfterMount(() => {
    refresh();
  }, [refresh]);

  useNavigated(() => refresh());

  const onEndReached = React.useCallback(() => {
    if (hasNext) {
      loadNext(20);
    }
  }, [hasNext, loadNext]);

  return (
    <SafeAreaView style={defaultStyles.full}>
      <FlashList
        testID="shipment-list"
        data={withoutEmptyEdges(data.customShipments.edges)}
        renderItem={({ item }) => (
          <ShipmentListPortalItem itemRef={item?.node!} />
        )}
        ListHeaderComponentStyle={defaultStyles.gutterTop}
        ListHeaderComponent={() => (
          <View
            style={{
              paddingHorizontal: 6,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                flex: 1,
              }}
            >
              <Chip
                onPress={() =>
                  setCurrentStatus(ShipmentPortalStatusLocally.OPEN)
                }
                selected={currentStatus === ShipmentPortalStatusLocally.OPEN}
                style={{ marginLeft: 6, marginBottom: 6 }}
              >
                Open
              </Chip>
              <Chip
                onPress={() =>
                  setCurrentStatus(ShipmentPortalStatusLocally.PROPOSED)
                }
                selected={
                  currentStatus === ShipmentPortalStatusLocally.PROPOSED
                }
                style={{ marginLeft: 6, marginBottom: 6 }}
              >
                Proposed
              </Chip>
              <Chip
                onPress={() =>
                  setCurrentStatus(ShipmentPortalStatusLocally.UPDATE_DETAILS)
                }
                selected={
                  currentStatus === ShipmentPortalStatusLocally.UPDATE_DETAILS
                }
                style={{ marginLeft: 6, marginBottom: 6 }}
              >
                Update details
              </Chip>
            </View>
            <View>
              <IconButton
                icon="logout"
                onPress={() => {
                  logout();
                }}
              />
            </View>
          </View>
        )}
        keyExtractor={(item) => item!.node!.id || 'no-id'}
        onEndReached={onEndReached}
        estimatedItemSize={63}
      />
    </SafeAreaView>
  );
}

export default React.memo(ShipmentListPortalScreen);
