import { registerScreen, lazy } from 'react-native-ridge-navigation';
import { loadQuery } from 'react-relay';

import { RequireAuthHOC } from './Auth/Authorization';
import RelayEnvironment from './RelayEnvironment';
import {
  getShipmentListPortalWhere,
  ShipmentPortalStatusLocally,
} from './ShipmentPortal/ShipmentListPortalScreen';
import { ContactDetailScreenQuery } from './__generated__/ContactDetailScreenQuery.graphql';
import { ContactListQuery } from './__generated__/ContactListQuery.graphql';
import { ConversationDetailScreenQuery } from './__generated__/ConversationDetailScreenQuery.graphql';
import { IncomingInvoicesScreenQuery } from './__generated__/IncomingInvoicesScreenQuery.graphql';
import { InvoiceDetailScreenInvoiceQuery } from './__generated__/InvoiceDetailScreenInvoiceQuery.graphql';
import { InvoicesScreenQuery } from './__generated__/InvoicesScreenQuery.graphql';
import { OutstandingInvoicesScreenQuery } from './__generated__/OutstandingInvoicesScreenQuery.graphql';
import { PaymentArrangementDetailScreenQuery } from './__generated__/PaymentArrangementDetailScreenQuery.graphql';
import { PaymentArrangementScreenQuery } from './__generated__/PaymentArrangementScreenQuery.graphql';
import { RateScreenQuery } from './__generated__/RateScreenQuery.graphql';
import { SettlementScreenQuery } from './__generated__/SettlementScreenQuery.graphql';
import { ShipmentDetailPortalScreenQuery } from './__generated__/ShipmentDetailPortalScreenQuery.graphql';
import { ShipmentDetailScreenShipmentQuery } from './__generated__/ShipmentDetailScreenShipmentQuery.graphql';
import { ShipmentListPortalScreenQuery } from './__generated__/ShipmentListPortalScreenQuery.graphql';
import { ShipmentsScreenQuery } from './__generated__/ShipmentsScreenQuery.graphql';
import type { SupplierDetailScreenQuery } from './__generated__/SupplierDetailScreenQuery.graphql';
import type { SupplierScreenQuery } from './__generated__/SupplierScreenQuery.graphql';
import { TransportDocumentDetailScreenQuery } from './__generated__/TransportDocumentDetailScreenQuery.graphql';
import { TransportDocumentListQuery } from './__generated__/TransportDocumentListQuery.graphql';

export const AuthScreen = registerScreen(
  '/auth',
  lazy(() => import('./Auth/AuthScreen')),
  () => {},
);

export const ShipmentsScreen = registerScreen(
  '/shipment',
  RequireAuthHOC(lazy(() => import('./Shipment/ShipmentsScreen'))),
  () => {
    const query =
      require('./__generated__/ShipmentsScreenQuery.graphql').default;
    return loadQuery<ShipmentsScreenQuery>(
      RelayEnvironment,
      query,
      {
        where: {
          customerInvoiceStatus: {
            equalTo: 'PENDING',
          },
        },
        search: '',
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const ShipmentAddScreen = registerScreen(
  '/shipment/add',
  RequireAuthHOC(lazy(() => import('./Shipment/Add/ShipmentAddScreen'))),
  () => {},
);

export const ShipmentDetailScreen = registerScreen(
  '/shipment/:id',
  RequireAuthHOC(lazy(() => import('./Shipment/Detail/ShipmentDetailScreen'))),
  ({ id }) => {
    const query =
      require('./__generated__/ShipmentDetailScreenShipmentQuery.graphql').default;
    return loadQuery<ShipmentDetailScreenShipmentQuery>(
      RelayEnvironment,
      query,
      {
        id,
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const IncomingInvoicesScreen = registerScreen(
  '/incoming-invoice',
  RequireAuthHOC(
    lazy(() => import('./IncomingInvoice/IncomingInvoicesScreen')),
  ),
  () => {
    const query =
      require('./__generated__/IncomingInvoicesScreenQuery.graphql').default;
    return loadQuery<IncomingInvoicesScreenQuery>(
      RelayEnvironment,
      query,
      {
        where: {
          weight: { moreThan: 0 },
        },
      },
      { fetchPolicy: 'network-only' },
    );
  },
);

export const OutstandingInvoicesScreen = registerScreen(
  '/outstanding-invoice',
  RequireAuthHOC(
    lazy(() => import('./OutstandingInvoice/OutstandingInvoicesScreen')),
  ),
  () => {
    const query =
      require('./__generated__/OutstandingInvoicesScreenQuery.graphql').default;
    return loadQuery<OutstandingInvoicesScreenQuery>(
      RelayEnvironment,
      query,
      { search: '' },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const InvoicesScreen = registerScreen(
  '/invoice',
  RequireAuthHOC(lazy(() => import('./Invoice/InvoicesScreen'))),
  () => {
    const query =
      require('./__generated__/InvoicesScreenQuery.graphql').default;
    return loadQuery<InvoicesScreenQuery>(
      RelayEnvironment,
      query,
      {
        // now: dateToUnix(new Date()),
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);
export const InvoiceDetailScreen = registerScreen(
  '/invoice/:id',
  RequireAuthHOC(lazy(() => import('./Invoice/Detail/InvoiceDetailScreen'))),
  ({ id }) => {
    const query =
      require('./__generated__/InvoiceDetailScreenInvoiceQuery.graphql').default;
    return loadQuery<InvoiceDetailScreenInvoiceQuery>(
      RelayEnvironment,
      query,
      {
        id,
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const TransportDocumentScreen = registerScreen(
  '/more/transport-documents',
  RequireAuthHOC(
    lazy(() => import('./TransportDocument/TransportDocumentScreen')),
  ),
  () => {
    const query =
      require('./__generated__/TransportDocumentListQuery.graphql').default;
    return loadQuery<TransportDocumentListQuery>(
      RelayEnvironment,
      query,
      {
        search: '',
        where: {},
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const TransportDocumentDetailScreen = registerScreen(
  '/more/transport-documents/:id',
  RequireAuthHOC(
    lazy(
      () => import('./TransportDocument/Detail/TransportDocumentDetailScreen'),
    ),
  ),
  ({ id }) => {
    const query =
      require('./__generated__/TransportDocumentDetailScreenQuery.graphql').default;
    return loadQuery<TransportDocumentDetailScreenQuery>(
      RelayEnvironment,
      query,
      { id },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const TransportDocumentSigningScreen = registerScreen(
  '/document/:token',
  lazy(
    () => import('./TransportDocument/Signing/TransportDocumentSigningScreen'),
  ),
  () => {},
);

export const MoreScreen = registerScreen(
  '/more',
  RequireAuthHOC(lazy(() => import('./More/MoreScreen'))),
  () => {},
);

export const SettlementScreen = registerScreen(
  '/more/settlements',
  RequireAuthHOC(lazy(() => import('./Settlements/SettlementScreen'))),
  ({}) => {
    const query =
      require('./__generated__/SettlementScreenQuery.graphql').default;
    return loadQuery<SettlementScreenQuery>(
      RelayEnvironment,
      query,
      {},
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const ShipmentListPortalScreen = registerScreen(
  '/portal/shipment',
  RequireAuthHOC(
    lazy(() => import('./ShipmentPortal/ShipmentListPortalScreen')),
  ),
  ({}) => {
    const query =
      require('./__generated__/ShipmentListPortalScreenQuery.graphql').default;
    return loadQuery<ShipmentListPortalScreenQuery>(
      RelayEnvironment,
      query,
      {
        where: getShipmentListPortalWhere(ShipmentPortalStatusLocally.OPEN),
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const ShipmentDetailPortalScreen = registerScreen(
  '/portal/shipment/:id',
  RequireAuthHOC(
    lazy(() => import('./ShipmentPortal/Detail/ShipmentDetailPortalScreen')),
  ),
  ({ id }) => {
    const query =
      require('./__generated__/ShipmentDetailPortalScreenQuery.graphql').default;
    return loadQuery<ShipmentDetailPortalScreenQuery>(
      RelayEnvironment,
      query,
      {
        id,
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const PaymentArrangementScreen = registerScreen(
  '/more/payment-arrangements',
  RequireAuthHOC(
    lazy(() => import('./PaymentArrangement/PaymentArrangementScreen')),
  ),
  ({}) => {
    const query =
      require('./__generated__/PaymentArrangementScreenQuery.graphql').default;
    return loadQuery<PaymentArrangementScreenQuery>(
      RelayEnvironment,
      query,
      {
        search: '',
        where: {},
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const PaymentArrangementAddScreen = registerScreen(
  '/more/payment-arrangements-add',
  RequireAuthHOC(
    lazy(() => import('./PaymentArrangement/Add/PaymentArrangementAddScreen')),
  ),
  ({}) => {},
);

export const PaymentArrangementDetailScreen = registerScreen(
  '/more/payment-arrangements/:id',
  RequireAuthHOC(
    lazy(
      () =>
        import('./PaymentArrangement/Detail/PaymentArrangementDetailScreen'),
    ),
  ),
  ({ id }) => {
    const query =
      require('./__generated__/PaymentArrangementDetailScreenQuery.graphql').default;
    return loadQuery<PaymentArrangementDetailScreenQuery>(
      RelayEnvironment,
      query,
      { id },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const SupplierScreen = registerScreen(
  '/more/supplier',
  RequireAuthHOC(lazy(() => import('./Supplier/SupplierScreen'))),
  () => {
    const query =
      require('./__generated__/SupplierScreenQuery.graphql').default;
    return loadQuery<SupplierScreenQuery>(
      RelayEnvironment,
      query,
      {},
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const SupplierDetailScreen = registerScreen(
  '/more/supplier/:id',
  RequireAuthHOC(lazy(() => import('./Supplier/Detail/SupplierDetailScreen'))),
  ({ id }) => {
    const query =
      require('./__generated__/SupplierDetailScreenQuery.graphql').default;
    return loadQuery<SupplierDetailScreenQuery>(
      RelayEnvironment,
      query,
      {
        id,
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const RateScreen = registerScreen(
  '/rate',
  RequireAuthHOC(lazy(() => import('./Rate/RateScreen'))),
  () => {
    const query = require('./__generated__/RateScreenQuery.graphql').default;
    return loadQuery<RateScreenQuery>(
      RelayEnvironment,
      query,
      {
        where: {
          totalAmountSentEur: { moreThan: 0 },
        },
        // now: dateToUnix(new Date()),
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const ContactListScreen = registerScreen(
  '/more/crm',
  RequireAuthHOC(lazy(() => import('./Contact/ContactListScreen'))),
  () => {
    // const query = require('./__generated__/ContactListQuery.graphql').default;
    //
    // return loadQuery<ContactListQuery>(
    //   RelayEnvironment,
    //   query,
    //   {
    //     input: {
    //       search: '',
    //       level: null,
    //       fromInteractionDate: null,
    //       toInteractionDate: null,
    //       labelId: null,
    //     },
    //   },
    //   { fetchPolicy: 'store-and-network' },
    // );
  },
);

export const ContactAddScreen = registerScreen(
  '/more/crm/add',
  RequireAuthHOC(lazy(() => import('./Contact/ContactAddScreen'))),
  () => {},
);

export const ContactDetailScreen = registerScreen(
  '/more/crm/:id',
  RequireAuthHOC(lazy(() => import('./Contact/ContactDetailScreen'))),
  ({ id }) => {
    const query =
      require('./__generated__/ContactDetailScreenQuery.graphql').default;
    return loadQuery<ContactDetailScreenQuery>(
      RelayEnvironment,
      query,
      {
        id,
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);

export const ConversationDetailScreen = registerScreen(
  '/more/conversation/:id',
  RequireAuthHOC(lazy(() => import('./Conversation/ConversationDetailScreen'))),
  ({ id }) => {
    const query =
      require('./__generated__/ConversationDetailScreenQuery.graphql').default;
    return loadQuery<ConversationDetailScreenQuery>(
      RelayEnvironment,
      query,
      {
        id,
      },
      { fetchPolicy: 'store-and-network' },
    );
  },
);
