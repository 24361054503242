import AsyncStorage from '@react-native-async-storage/async-storage';
import { newRidgeState } from 'react-ridge-state';

import { AuthorizationUtilsUser$data } from '../__generated__/AuthorizationUtilsUser.graphql';

export interface AuthState {
  user: AuthorizationUtilsUser$data | null;
  token: string | null | undefined;
  resolving: undefined | false | true;
}
export const emptyAuthState: AuthState = {
  resolving: undefined,
  user: null,
  token: undefined,
};

const authState = newRidgeState<AuthState>(emptyAuthState, {
  onSet: (newState) => {
    if (newState.user && newState.token && !newState.resolving) {
      // save to local storage
      AsyncStorage.setItem('auth', JSON.stringify(newState));
    }
  },
});

export function reset() {
  authState.reset();
  return AsyncStorage.removeItem('auth');
}

export function isAuthorized(): boolean {
  return !!authState.get().token;
}

export function getToken(): string | null | undefined {
  const { token } = authState.get();
  return token;
}

export default authState;
