import {
  Montserrat_400Regular,
  Montserrat_500Medium,
  Montserrat_600SemiBold,
  useFonts,
} from '@expo-google-fonts/montserrat';
import { PortalProvider } from '@gorhom/portal';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';
import { ColorSchemeName, Platform, useColorScheme } from 'react-native';
import {
  Provider as PaperProvider,
  MD3LightTheme,
  configureFonts,
} from 'react-native-paper';
import { nl, registerTranslation } from 'react-native-paper-dates';
import {
  NavigationProvider,
  createBottomTabsRoot,
  createNormalRoot,
  createSimpleTheme,
} from 'react-native-ridge-navigation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  nl as nlForm,
  registerTranslation as registerTranslationForm,
  registerDefaultLocale,
} from 'react-native-use-form';
import { RelayEnvironmentProvider } from 'react-relay/hooks';

import AsyncBoundary from './AsyncBoundary';
import AsyncBoundaryScreen from './AsyncBoundaryScreen';
import { useRole } from './Auth/Authorization';
import { getRootBasedOnRole } from './Auth/RedirectBasedOnRole';
import BottomRootStart from './BottomRootStart';
import AlertContainerLazy from './Global/AlertContainerLazy';
import NavigationRoots from './NavigationRoots';
import { BottomRoots, screens } from './Navigator';
import routes from './NavigatorRoutes';
import environment from './RelayEnvironment';
import { breakingPointWidth } from './breakpoint';
import { darkColors, lightColors } from './colors';
import { fontConfig } from './fontConfig';
import { WindowSizeProvider } from './useWindowSize';
registerTranslation('nl', nl);
registerTranslationForm('nl', nlForm);
registerDefaultLocale('nl');

function getTheme(colorScheme: ColorSchemeName): typeof MD3LightTheme {
  const isDark = colorScheme === 'dark';

  return {
    version: 3,
    isV3: true,
    mode: 'adaptive',
    dark: isDark,
    roundness: 4,
    animation: {
      scale: 1.0,
    },
    colors: isDark ? darkColors : lightColors,
    fonts: configureFonts({ config: fontConfig }),
  };
}

const bottomRootConfig = {
  breakingPointWidth,
  components: {
    start: BottomRootStart,
  },
};

const darkTheme = getTheme('dark');
const lightTheme = getTheme('light');
//  primary: '#3087e6',
//   primaryLight: '#c9e0f8',
//   accent: '#3087e6',
const themeSettings = createSimpleTheme({
  light: {
    primary: lightTheme.colors.primary,
    accent: lightTheme.colors.secondary,
    text: lightTheme.colors.onBackground,
    backgroundColor: lightTheme.colors.background,
    bottomTabs: {
      fontFamily: 'Montserrat_600SemiBold',
      textColor: lightTheme.colors.onBackground,
      activeIndicatorColor: lightTheme.colors.surfaceVariant,
      selectedTextColor: lightTheme.colors.primary,
      backgroundColor: lightTheme.colors.background,
    },
  },
  dark: {
    primary: darkTheme.colors.primary,
    accent: darkTheme.colors.secondary,
    text: darkTheme.colors.onBackground,
    backgroundColor: darkTheme.colors.background,
    bottomTabs: {
      fontFamily: 'Montserrat_600SemiBold',
      textColor: darkTheme.colors.onBackground,
      activeIndicatorColor: darkTheme.colors.surfaceVariant,
      selectedTextColor: darkTheme.colors.primary,
      backgroundColor: darkTheme.colors.background,
    },
  },
});

const navigationRoot = {
  [NavigationRoots.RootAdmin]: createBottomTabsRoot(
    [
      BottomRoots.Shipment,
      BottomRoots.IncomingInvoice,
      BottomRoots.OutstandingInvoice,
      BottomRoots.Contact,
      BottomRoots.More,
    ],
    bottomRootConfig,
  ),
  [NavigationRoots.RootAuth]: createNormalRoot(routes.AuthScreen),
  [NavigationRoots.SigningScreen]: createNormalRoot(
    routes.TransportDocumentSigningScreen,
  ),
  [NavigationRoots.Portal]: createNormalRoot(routes.ShipmentListPortalScreen),
};

export default function App() {
  const role = useRole();
  const colorScheme = useColorScheme(); // Can be dark | light | no-preference

  const paperTheme = React.useMemo(
    () => (colorScheme === 'light' ? lightTheme : darkTheme),
    [colorScheme],
  );

  const [fontsLoaded] = useFonts({
    Montserrat_400Regular,
    Montserrat_500Medium,
    Montserrat_600SemiBold,
  });

  React.useEffect(() => {
    if (fontsLoaded) {
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  if (!fontsLoaded && Platform.OS !== 'web') {
    return null;
  }

  return (
    <>
      <PortalProvider>
        <SafeAreaProvider>
          <WindowSizeProvider>
            <RelayEnvironmentProvider environment={environment as any}>
              <PaperProvider theme={paperTheme}>
                <AsyncBoundary>
                  <NavigationProvider
                    screens={screens}
                    SuspenseContainer={AsyncBoundaryScreen}
                    navigationRoot={navigationRoot}
                    themeSettings={themeSettings}
                    initialRootKey={getRootBasedOnRole(role)}
                  />
                </AsyncBoundary>
                <React.Suspense fallback={null}>
                  <AlertContainerLazy />
                </React.Suspense>
              </PaperProvider>
            </RelayEnvironmentProvider>
          </WindowSizeProvider>
        </SafeAreaProvider>
      </PortalProvider>
    </>
  );
}
