import * as React from "react";
import { useWindowDimensions } from "react-native";

const WidthAndHeightContext = React.createContext<{
  width: number;
  height: number;
}>({ width: 0, height: 0 });

export function WindowSizeProvider({ children }: { children: any }) {
  const value = useWindowDimensions();
  return (
    <WidthAndHeightContext.Provider value={value}>
      {children}
    </WidthAndHeightContext.Provider>
  );
}

export function useWindowSize() {
  return React.useContext(WidthAndHeightContext);
}
