import AsyncStorage from '@react-native-async-storage/async-storage';
import { ComponentType } from 'react';
import { SwitchRoot } from 'react-native-ridge-navigation';

import authState, { AuthState } from './AuthState';
import { fetchAndSaveProfileForToken } from './AuthorizationUtils';
import NavigationRoots from '../NavigationRoots';

// getInitialState fetches data from localStorage
async function setInitialState() {
  let initialState;
  try {
    const item = await AsyncStorage.getItem('auth');
    if (item) {
      initialState = JSON.parse(item);
      // always try to refresh store in background
      fetchAndSaveProfileForToken({
        token: initialState.token,
      });
    }
  } catch (error) {}

  return initialState;
}
setInitialState();

// EXTERNAL FUNCTIONS AND COMPONENTS

export function RequireAuthHOC<T>(WrappedComponent: ComponentType<T>) {
  const func = function (props: T) {
    return (
      <RequireAuth>
        <WrappedComponent {...props} />
      </RequireAuth>
    );
  };
  (func as any).preload = (WrappedComponent as any).preload;
  return func;
}

export function RequireAuth({ children }: { children?: any }) {
  const { token, resolving, user } = useAuthState();

  if (resolving) {
    return null;
  }

  if (!resolving && (!token || !user)) {
    return <SwitchRoot rootKey={NavigationRoots.RootAuth} />;
  }

  // user is cached locally
  if (token && user) {
    return children;
  }

  return null;
}
export function OptionalAuth({ children }: { children?: any }) {
  return children;
}

// useToken for easy getting your token in your components
export function useToken(): string | null | undefined {
  const state = authState.useValue();
  return state.token;
}

export function useRole() {
  return useAuthState().user?.role!;
}

export function useUser() {
  return useAuthState().user;
}

export function useIsAdmin() {
  const role = useRole();
  return ['ADMIN', 'SUPER_ADMIN'].includes(role);
}

// useAuthState for easy getting your auth data in your components
interface AuthStateHooks extends AuthState {
  isAuthenticated: boolean;
}

export function useAuthState(): AuthStateHooks {
  const state = authState.useValue();
  const isAuthenticated = !!state.user;

  return {
    ...state,
    isAuthenticated,
  };
}

export function getAuthState() {
  return authState.get();
}

export function logout() {
  authState.set({
    token: null,
    user: null,
    resolving: false,
  });
  AsyncStorage.removeItem('auth');
}
