import { ProgressBar } from 'react-native-paper';
import { SwitchRoot } from 'react-native-ridge-navigation';

import { useRole, useUser } from './Authorization';
import NavigationRoots from '../NavigationRoots';

export function getRootBasedOnRole(role: any) {
  switch (role) {
    case 'CUSTOMER':
      return NavigationRoots.Portal;
    case 'TRANSPORTER':
      return NavigationRoots.Portal;
    default:
      return NavigationRoots.RootAdmin;
  }
}

export default function RedirectBasedOnRole() {
  const user = useUser();
  const role = useRole();
  if (!user) {
    return <ProgressBar />;
  }

  const rootKey = getRootBasedOnRole(role);

  if (rootKey) {
    return <SwitchRoot rootKey={rootKey} />;
  }
  return null;
}
