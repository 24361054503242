/**
 * @generated SignedSource<<6cbe68b4f1347598e92659dd32305b65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PaymentArrangementDetailScreenQuery$variables = {
  id: string;
};
export type PaymentArrangementDetailScreenQuery$data = {
  readonly paymentArrangement: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"PaymentArrangementDetailFragment" | "PaymentArrangementDetailScreenAppbarFragment">;
  };
};
export type PaymentArrangementDetailScreenQuery = {
  response: PaymentArrangementDetailScreenQuery$data;
  variables: PaymentArrangementDetailScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentArrangementDetailScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaymentArrangement",
        "kind": "LinkedField",
        "name": "paymentArrangement",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PaymentArrangementDetailFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PaymentArrangementDetailScreenAppbarFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaymentArrangementDetailScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaymentArrangement",
        "kind": "LinkedField",
        "name": "paymentArrangement",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Customer",
            "kind": "LinkedField",
            "name": "customer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Invoice",
            "kind": "LinkedField",
            "name": "invoices",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "remainingAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cancelled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "finished",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "extraAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "termFrequencyInDays",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstTermDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastTermAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "termAmount",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalTerms",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "invoicesTotalAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentTerm",
            "kind": "LinkedField",
            "name": "paymentTerms",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPaid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paidAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "warningEmailSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02642f68e7191ff62f3dd812ba5a2406",
    "id": null,
    "metadata": {},
    "name": "PaymentArrangementDetailScreenQuery",
    "operationKind": "query",
    "text": "query PaymentArrangementDetailScreenQuery(\n  $id: ID!\n) {\n  paymentArrangement(id: $id) {\n    id\n    ...PaymentArrangementDetailFragment\n    ...PaymentArrangementDetailScreenAppbarFragment\n  }\n}\n\nfragment PaymentArrangementDetailFragment on PaymentArrangement {\n  id\n  customer {\n    id\n    name\n    currency\n    user {\n      id\n    }\n  }\n  invoices {\n    id\n    remainingAmount\n  }\n  cancelled\n  finished\n  extraAmount\n  totalAmount\n  termFrequencyInDays\n  firstTermDate\n  lastTermAmount\n  termAmount\n  currency\n  totalTerms\n  invoicesTotalAmount\n  paymentTerms {\n    id\n    ...PaymentArrangementDetailTermsListItem\n  }\n}\n\nfragment PaymentArrangementDetailScreenAppbarFragment on PaymentArrangement {\n  id\n  totalAmount\n  cancelled\n}\n\nfragment PaymentArrangementDetailTermsListItem on PaymentTerm {\n  id\n  isPaid\n  paidAt\n  warningEmailSent\n  dueDate\n  amount\n  currency\n}\n"
  }
};
})();

(node as any).hash = "b17a8b5921df819c3104edd57a7479d4";

export default node;
