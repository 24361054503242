import { extractFiles } from 'extract-files';
import { Platform } from 'react-native';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

// @ts-ignore
import { getToken } from './Auth/AuthState';

let url = 'https://ddtradingapp.nl';
if (process.env.NODE_ENV === 'development') {
  if (Platform.OS === 'web') {
    // url = 'http://192.168.11.16:3000';
    url = 'http://localhost:44300';
  } else {
    // add to android/app/src/main/res/network_security_config.xml too!
    // url = 'http://10.0.2.2:9090'; // robin
    // wsUrl = 'ws://10.0.2.2:9090'; // robin
    // url = 'http://192.168.11.16:44300'; // richard
    // url = 'http://192.168.31.2:44300'; // robin
  }
}

async function fetchGraphQL(
  params: any,
  variables: any,
  _cacheConfig: any,
  uploadables: any,
) {
  const headers: any = {};
  const token = getToken();
  if (token) {
    headers.Authorization = token;
  }
  const request: any = {
    method: 'POST',
    headers,
    body: {},
  };

  const operations = {
    query: params.text,
    variables,
  };

  // https://github.com/relay-tools/react-relay-network-modern/issues/41#issuecomment-513462547
  if (uploadables) {
    // @ts-ignore
    const {
      clone: extractedOperations,
      files,
    }: /* global File */
    { clone: any; files: File[] } = extractFiles(operations);
    const formData = new FormData();
    formData.append('operations', JSON.stringify(extractedOperations));

    const pathMap = {};
    let i = 0;
    files.forEach((paths: any) => {
      //@ts-ignore
      pathMap[++i] = paths;
    });
    formData.append('map', JSON.stringify(pathMap));

    i = 0;
    //@ts-ignore
    files.forEach((paths: any, file: File) => {
      //@ts-ignore
      formData.append(++i, file, file.name);
    });

    request.body = formData;
  } else {
    request.headers['Content-Type'] = 'application/json';
    request.body = JSON.stringify(operations);
  }

  const response = await fetch(url + '/graphql', request);
  // Get the response as JSON
  const json = await response.json();

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    console.log(json.errors);
  }

  // Otherwise, return the full payload.
  return json;
}

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
async function fetchRelay(
  params: any,
  variables: any,
  _cacheConfig: any,
  uploadables: any,
) {
  console.log(
    `fetching query ${params.name} with ${JSON.stringify(variables)}`,
  );
  return fetchGraphQL(params, variables, _cacheConfig, uploadables);
}

const isDev = process.env.NODE_ENV === 'development';

// Export a singleton instance of Relay Environment configured with our network function:
const localEnvironment = new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource(), {
    gcReleaseBufferSize: 2000,
  }),
}) as any;

// console.log('env before exporting', env)

//@ts-ignore
if (isDev && window) {
  //@ts-ignore
  window.relayEnvironment = localEnvironment;
  //@ts-ignore
  window.debugRelayStore = () =>
    localEnvironment.getStore().getSource().toJSON();
}

export default localEnvironment;
