/**
 * @generated SignedSource<<fdf5ffd062cd70e87e190e9f9c31ef04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoiceDetailScreenInvoiceQuery$variables = {
  id: string;
};
export type InvoiceDetailScreenInvoiceQuery$data = {
  readonly customerWithDueAmount: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"InvoiceDetailFragment" | "InvoiceDetailScreenAppbarFragment">;
  };
};
export type InvoiceDetailScreenInvoiceQuery = {
  response: InvoiceDetailScreenInvoiceQuery$data;
  variables: InvoiceDetailScreenInvoiceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoiceDetailScreenInvoiceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomerWithDueAmount",
        "kind": "LinkedField",
        "name": "customerWithDueAmount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InvoiceDetailFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InvoiceDetailScreenAppbarFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvoiceDetailScreenInvoiceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomerWithDueAmount",
        "kind": "LinkedField",
        "name": "customerWithDueAmount",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remainingAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "openInterest",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d0a11cc5a1855168ec78396e7629a8fe",
    "id": null,
    "metadata": {},
    "name": "InvoiceDetailScreenInvoiceQuery",
    "operationKind": "query",
    "text": "query InvoiceDetailScreenInvoiceQuery(\n  $id: ID!\n) {\n  customerWithDueAmount(id: $id) {\n    id\n    ...InvoiceDetailFragment\n    ...InvoiceDetailScreenAppbarFragment\n  }\n}\n\nfragment InvoiceDetailFragment on CustomerWithDueAmount {\n  id\n  name\n  currency\n  remainingAmount\n  openInterest\n}\n\nfragment InvoiceDetailScreenAppbarFragment on CustomerWithDueAmount {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "f60dcdefe86152c91dba4a358f93188a";

export default node;
