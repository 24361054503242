/**
 * @generated SignedSource<<f1aadfef88b42394df90869424650f2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoiceStatus = "PAID" | "SENT";
export type PreviousInvoiceCustomerInvoiceStatus = "PENDING" | "SENT";
export type ShipmentCustomerInvoiceStatus = "PENDING" | "SENT";
export type ShipmentStatus = "CONCEPT" | "SENT";
export type ShipmentSupplierInvoiceStatus = "PAID" | "UNPAID";
export type ShipmentTransporterInvoiceStatus = "PAID" | "UNPAID";
export type UserRole = "ADMIN" | "CUSTOMER" | "TRANSPORTER";
export type TransportDocumentWhere = {
  and?: TransportDocumentWhere | null;
  createdAt?: TimeUnixFilter | null;
  deletedAt?: TimeUnixFilter | null;
  documentUrl?: StringFilter | null;
  id?: IDFilter | null;
  or?: TransportDocumentWhere | null;
  sendAt?: TimeUnixFilter | null;
  shipment?: ShipmentWhere | null;
  signatureUrl?: StringFilter | null;
  signedAt?: TimeUnixFilter | null;
  signingToken?: StringFilter | null;
  updatedAt?: TimeUnixFilter | null;
  withDeleted?: boolean | null;
};
export type IDFilter = {
  equalTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isNull?: boolean | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notNull?: boolean | null;
};
export type ShipmentWhere = {
  abcDelivery?: BooleanFilter | null;
  and?: ShipmentWhere | null;
  article?: ArticleWhere | null;
  commissionair?: SupplierWhere | null;
  costPrice?: FloatFilter | null;
  createdAt?: TimeUnixFilter | null;
  customer?: CustomerWhere | null;
  customerCustomAddress?: StringFilter | null;
  customerCustomCity?: StringFilter | null;
  customerCustomCountry?: StringFilter | null;
  customerCustomName?: StringFilter | null;
  customerCustomPostalcode?: StringFilter | null;
  customerInvoiceStatus?: ShipmentCustomerInvoiceStatusFilter | null;
  customerInvoiceVismaId?: IDFilter | null;
  customerWeight?: FloatFilter | null;
  deletedAt?: TimeUnixFilter | null;
  deliveryWithGlobalGap?: BooleanFilter | null;
  detailEmailSentToSupplier?: BooleanFilter | null;
  driverTelephone?: StringFilter | null;
  exclude?: BooleanFilter | null;
  expectedDeliveryDate?: TimeUnixFilter | null;
  globalGapGrower?: StringFilter | null;
  group?: GroupWhere | null;
  hasDifferentCustomerAddress?: BooleanFilter | null;
  hasDifferentSupplierAddress?: BooleanFilter | null;
  hasMarge?: BooleanFilter | null;
  id?: IDFilter | null;
  invoice?: InvoiceWhere | null;
  licensePlate?: StringFilter | null;
  margeCostPriceEur?: FloatFilter | null;
  margeSellingPriceEur?: FloatFilter | null;
  oldCostPrice?: FloatFilter | null;
  oldSellingPrice?: FloatFilter | null;
  or?: ShipmentWhere | null;
  previousInvoices?: PreviousInvoiceWhere | null;
  previousLicensePlate?: StringFilter | null;
  privateComment?: StringFilter | null;
  proposal?: ProposalWhere | null;
  proposals?: ProposalWhere | null;
  publicComment?: StringFilter | null;
  reasonForRejection?: StringFilter | null;
  rejected?: BooleanFilter | null;
  rejectionCostNet?: FloatFilter | null;
  risk?: IntFilter | null;
  sellingPrice?: FloatFilter | null;
  sellingPriceEur?: FloatFilter | null;
  shipmentDate?: TimeUnixFilter | null;
  solution?: StringFilter | null;
  status?: ShipmentStatusFilter | null;
  supplier?: SupplierWhere | null;
  supplierCustomAddress?: StringFilter | null;
  supplierCustomCity?: StringFilter | null;
  supplierCustomCountry?: StringFilter | null;
  supplierCustomName?: StringFilter | null;
  supplierCustomPostalcode?: StringFilter | null;
  supplierInvoice1?: StringFilter | null;
  supplierInvoice1Name?: StringFilter | null;
  supplierInvoice2?: StringFilter | null;
  supplierInvoice2Name?: StringFilter | null;
  supplierInvoice3?: StringFilter | null;
  supplierInvoice3Name?: StringFilter | null;
  supplierInvoiceStatus?: ShipmentSupplierInvoiceStatusFilter | null;
  supplierWeight?: FloatFilter | null;
  transportDocument?: TransportDocumentWhere | null;
  transportationPrice?: FloatFilter | null;
  transportationPriceEur?: FloatFilter | null;
  transporter?: SupplierWhere | null;
  transporterInvoice1?: StringFilter | null;
  transporterInvoice1Name?: StringFilter | null;
  transporterInvoice2?: StringFilter | null;
  transporterInvoice2Name?: StringFilter | null;
  transporterInvoice3?: StringFilter | null;
  transporterInvoice3Name?: StringFilter | null;
  transporterInvoiceStatus?: ShipmentTransporterInvoiceStatusFilter | null;
  transporterWeight?: FloatFilter | null;
  updatedAt?: TimeUnixFilter | null;
  waitForCorrection?: BooleanFilter | null;
  weight?: FloatFilter | null;
  withDeleted?: boolean | null;
};
export type ShipmentStatusFilter = {
  equalTo?: ShipmentStatus | null;
  in?: ReadonlyArray<ShipmentStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: ShipmentStatus | null;
  notIn?: ReadonlyArray<ShipmentStatus> | null;
  notNull?: boolean | null;
};
export type SupplierWhere = {
  address?: StringFilter | null;
  and?: SupplierWhere | null;
  bankNumber?: StringFilter | null;
  city?: StringFilter | null;
  commissionairShipments?: ShipmentWhere | null;
  createdAt?: TimeUnixFilter | null;
  currency?: StringFilter | null;
  deletedAt?: TimeUnixFilter | null;
  email?: StringFilter | null;
  externalId?: IDFilter | null;
  globalGapExpiredEmailSent?: BooleanFilter | null;
  globalGapNumber?: IntFilter | null;
  globalGapUrl?: StringFilter | null;
  globalGapValidTill?: TimeUnixFilter | null;
  id?: IDFilter | null;
  language?: StringFilter | null;
  lastCheckedGlobalGapValidTill?: TimeUnixFilter | null;
  name?: StringFilter | null;
  or?: SupplierWhere | null;
  postalcode?: StringFilter | null;
  registrationUrl?: StringFilter | null;
  settlements?: SettlementWhere | null;
  shipments?: ShipmentWhere | null;
  taxNumber?: StringFilter | null;
  transporterShipments?: ShipmentWhere | null;
  updatedAt?: TimeUnixFilter | null;
  user?: UserWhere | null;
  withDeleted?: boolean | null;
};
export type StringFilter = {
  contain?: string | null;
  containStrict?: string | null;
  endWith?: string | null;
  endWithStrict?: string | null;
  equalTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isEmpty?: boolean | null;
  isNull?: boolean | null;
  isNullOrEmpty?: boolean | null;
  notContain?: string | null;
  notContainStrict?: string | null;
  notEmpty?: boolean | null;
  notEndWith?: string | null;
  notEndWithStrict?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notNull?: boolean | null;
  notNullOrEmpty?: boolean | null;
  notStartWith?: string | null;
  notStartWithStrict?: string | null;
  startWith?: string | null;
  startWithStrict?: string | null;
};
export type UserWhere = {
  and?: UserWhere | null;
  createdAt?: TimeUnixFilter | null;
  customer?: CustomerWhere | null;
  email?: StringFilter | null;
  id?: IDFilter | null;
  or?: UserWhere | null;
  password?: StringFilter | null;
  proposals?: ProposalWhere | null;
  role?: UserRoleFilter | null;
  supplier?: SupplierWhere | null;
  updatedAt?: TimeUnixFilter | null;
  withDeleted?: boolean | null;
};
export type UserRoleFilter = {
  equalTo?: UserRole | null;
  in?: ReadonlyArray<UserRole> | null;
  isNull?: boolean | null;
  notEqualTo?: UserRole | null;
  notIn?: ReadonlyArray<UserRole> | null;
  notNull?: boolean | null;
};
export type TimeUnixFilter = {
  equalTo?: number | null;
  isNull?: boolean | null;
  isNullOrZero?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notNull?: boolean | null;
  notNullOrZero?: boolean | null;
};
export type CustomerWhere = {
  and?: CustomerWhere | null;
  createdAt?: TimeUnixFilter | null;
  currency?: StringFilter | null;
  deletedAt?: TimeUnixFilter | null;
  email?: StringFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  invoiceAddress?: StringFilter | null;
  invoiceCity?: StringFilter | null;
  invoicePostalcode?: StringFilter | null;
  invoices?: InvoiceWhere | null;
  language?: StringFilter | null;
  name?: StringFilter | null;
  or?: CustomerWhere | null;
  paymentArrangements?: PaymentArrangementWhere | null;
  payments?: PaymentWhere | null;
  shipments?: ShipmentWhere | null;
  updatedAt?: TimeUnixFilter | null;
  user?: UserWhere | null;
  vatNumber?: StringFilter | null;
  withDeleted?: boolean | null;
};
export type InvoiceWhere = {
  and?: InvoiceWhere | null;
  createdAt?: TimeUnixFilter | null;
  customer?: CustomerWhere | null;
  deletedAt?: TimeUnixFilter | null;
  deliveryDate?: TimeUnixFilter | null;
  dueDate?: TimeUnixFilter | null;
  exchangeRateExpiredEur?: FloatFilter | null;
  exchangeRatePaidEur?: FloatFilter | null;
  exchangeRateSentEur?: FloatFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  inflationRate?: FloatFilter | null;
  invoiceDate?: TimeUnixFilter | null;
  invoiceNumber?: IntFilter | null;
  latestInterestSentDate?: TimeUnixFilter | null;
  or?: InvoiceWhere | null;
  paidDate?: TimeUnixFilter | null;
  paymentArrangement?: PaymentArrangementWhere | null;
  paymentStatus?: IntFilter | null;
  previousInvoice?: PreviousInvoiceWhere | null;
  remainingAmount?: FloatFilter | null;
  shipments?: ShipmentWhere | null;
  status?: InvoiceStatusFilter | null;
  totalAmount?: FloatFilter | null;
  totalAmountExpiredEur?: FloatFilter | null;
  totalAmountPaidEur?: FloatFilter | null;
  totalAmountSentEur?: FloatFilter | null;
  updatedAt?: TimeUnixFilter | null;
  withDeleted?: boolean | null;
};
export type InvoiceStatusFilter = {
  equalTo?: InvoiceStatus | null;
  in?: ReadonlyArray<InvoiceStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: InvoiceStatus | null;
  notIn?: ReadonlyArray<InvoiceStatus> | null;
  notNull?: boolean | null;
};
export type FloatFilter = {
  equalTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  isNullOrZero?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
  notNull?: boolean | null;
  notNullOrZero?: boolean | null;
};
export type IntFilter = {
  equalTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  isNullOrZero?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
  notNull?: boolean | null;
  notNullOrZero?: boolean | null;
};
export type PaymentArrangementWhere = {
  and?: PaymentArrangementWhere | null;
  cancelled?: BooleanFilter | null;
  cancelledAt?: TimeUnixFilter | null;
  createdAt?: TimeUnixFilter | null;
  currency?: StringFilter | null;
  customer?: CustomerWhere | null;
  deletedAt?: TimeUnixFilter | null;
  extraAmount?: FloatFilter | null;
  finished?: BooleanFilter | null;
  finishedAt?: TimeUnixFilter | null;
  firstTermDate?: TimeUnixFilter | null;
  id?: IDFilter | null;
  invoices?: InvoiceWhere | null;
  invoicesTotalAmount?: FloatFilter | null;
  lastTermAmount?: FloatFilter | null;
  or?: PaymentArrangementWhere | null;
  paymentTerms?: PaymentTermWhere | null;
  termAmount?: FloatFilter | null;
  termFrequencyInDays?: IntFilter | null;
  totalAmount?: FloatFilter | null;
  totalTerms?: IntFilter | null;
  updatedAt?: TimeUnixFilter | null;
  withDeleted?: boolean | null;
};
export type BooleanFilter = {
  equalTo?: boolean | null;
  isNull?: boolean | null;
  notEqualTo?: boolean | null;
  notNull?: boolean | null;
};
export type PaymentTermWhere = {
  amount?: FloatFilter | null;
  and?: PaymentTermWhere | null;
  createdAt?: TimeUnixFilter | null;
  currency?: StringFilter | null;
  deletedAt?: TimeUnixFilter | null;
  dueDate?: TimeUnixFilter | null;
  id?: IDFilter | null;
  isPaid?: BooleanFilter | null;
  or?: PaymentTermWhere | null;
  paidAt?: TimeUnixFilter | null;
  payment?: PaymentWhere | null;
  paymentArrangement?: PaymentArrangementWhere | null;
  updatedAt?: TimeUnixFilter | null;
  warningEmailSent?: BooleanFilter | null;
  warningEmailSentAt?: TimeUnixFilter | null;
  withDeleted?: boolean | null;
};
export type PaymentWhere = {
  amountInLowestUnit?: IntFilter | null;
  and?: PaymentWhere | null;
  createdAt?: TimeUnixFilter | null;
  currency?: StringFilter | null;
  customer?: CustomerWhere | null;
  deletedAt?: TimeUnixFilter | null;
  id?: IDFilter | null;
  isPaid?: BooleanFilter | null;
  or?: PaymentWhere | null;
  paidAt?: TimeUnixFilter | null;
  paymentTerm?: PaymentTermWhere | null;
  sessionId?: IDFilter | null;
  sign?: StringFilter | null;
  updatedAt?: TimeUnixFilter | null;
  withDeleted?: boolean | null;
};
export type PreviousInvoiceWhere = {
  and?: PreviousInvoiceWhere | null;
  customerInvoiceStatus?: PreviousInvoiceCustomerInvoiceStatusFilter | null;
  id?: IDFilter | null;
  invoice?: InvoiceWhere | null;
  or?: PreviousInvoiceWhere | null;
  shipment?: ShipmentWhere | null;
  withDeleted?: boolean | null;
};
export type PreviousInvoiceCustomerInvoiceStatusFilter = {
  equalTo?: PreviousInvoiceCustomerInvoiceStatus | null;
  in?: ReadonlyArray<PreviousInvoiceCustomerInvoiceStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: PreviousInvoiceCustomerInvoiceStatus | null;
  notIn?: ReadonlyArray<PreviousInvoiceCustomerInvoiceStatus> | null;
  notNull?: boolean | null;
};
export type ProposalWhere = {
  and?: ProposalWhere | null;
  createdAt?: TimeUnixFilter | null;
  deletedAt?: TimeUnixFilter | null;
  id?: IDFilter | null;
  or?: ProposalWhere | null;
  price?: FloatFilter | null;
  shipment?: ShipmentWhere | null;
  shipments?: ShipmentWhere | null;
  updatedAt?: TimeUnixFilter | null;
  user?: UserWhere | null;
  withDeleted?: boolean | null;
};
export type SettlementWhere = {
  alreadyPaidAmount?: FloatFilter | null;
  and?: SettlementWhere | null;
  createdAt?: TimeUnixFilter | null;
  deletedAt?: TimeUnixFilter | null;
  group?: GroupWhere | null;
  id?: IDFilter | null;
  or?: SettlementWhere | null;
  sendAt?: TimeUnixFilter | null;
  supplier?: SupplierWhere | null;
  token?: StringFilter | null;
  updatedAt?: TimeUnixFilter | null;
  withDeleted?: boolean | null;
};
export type GroupWhere = {
  and?: GroupWhere | null;
  createdAt?: TimeUnixFilter | null;
  deletedAt?: TimeUnixFilter | null;
  id?: IDFilter | null;
  name?: StringFilter | null;
  note?: StringFilter | null;
  or?: GroupWhere | null;
  settlement?: SettlementWhere | null;
  shipments?: ShipmentWhere | null;
  updatedAt?: TimeUnixFilter | null;
  withDeleted?: boolean | null;
};
export type ArticleWhere = {
  and?: ArticleWhere | null;
  createdAt?: TimeUnixFilter | null;
  deletedAt?: TimeUnixFilter | null;
  externalId?: IDFilter | null;
  id?: IDFilter | null;
  name?: StringFilter | null;
  nameEnglish?: StringFilter | null;
  or?: ArticleWhere | null;
  shipments?: ShipmentWhere | null;
  updatedAt?: TimeUnixFilter | null;
  withDeleted?: boolean | null;
};
export type ShipmentCustomerInvoiceStatusFilter = {
  equalTo?: ShipmentCustomerInvoiceStatus | null;
  in?: ReadonlyArray<ShipmentCustomerInvoiceStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: ShipmentCustomerInvoiceStatus | null;
  notIn?: ReadonlyArray<ShipmentCustomerInvoiceStatus> | null;
  notNull?: boolean | null;
};
export type ShipmentSupplierInvoiceStatusFilter = {
  equalTo?: ShipmentSupplierInvoiceStatus | null;
  in?: ReadonlyArray<ShipmentSupplierInvoiceStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: ShipmentSupplierInvoiceStatus | null;
  notIn?: ReadonlyArray<ShipmentSupplierInvoiceStatus> | null;
  notNull?: boolean | null;
};
export type ShipmentTransporterInvoiceStatusFilter = {
  equalTo?: ShipmentTransporterInvoiceStatus | null;
  in?: ReadonlyArray<ShipmentTransporterInvoiceStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: ShipmentTransporterInvoiceStatus | null;
  notIn?: ReadonlyArray<ShipmentTransporterInvoiceStatus> | null;
  notNull?: boolean | null;
};
export type TransportDocumentListQuery$variables = {
  search?: string | null;
  where?: TransportDocumentWhere | null;
};
export type TransportDocumentListQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TransportDocumentListFragment">;
};
export type TransportDocumentListQuery = {
  response: TransportDocumentListQuery$data;
  variables: TransportDocumentListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = [
  {
    "fields": (v1/*: any*/),
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "ordering",
    "value": [
      {
        "direction": "DESC",
        "sort": "ID"
      }
    ]
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransportDocumentListQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "TransportDocumentListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransportDocumentListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TransportDocumentConnection",
        "kind": "LinkedField",
        "name": "transportDocuments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TransportDocumentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransportDocument",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sendAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "documentUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signingToken",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Shipment",
                    "kind": "LinkedField",
                    "name": "shipment",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Group",
                        "kind": "LinkedField",
                        "name": "group",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "note",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Customer",
                        "kind": "LinkedField",
                        "name": "customer",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Supplier",
                        "kind": "LinkedField",
                        "name": "supplier",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Article",
                        "kind": "LinkedField",
                        "name": "article",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "ordering",
          "filter"
        ],
        "handle": "connection",
        "key": "TransportDocumentList_transportDocuments",
        "kind": "LinkedHandle",
        "name": "transportDocuments"
      }
    ]
  },
  "params": {
    "cacheID": "cb536b47d49e70f805e80b463051d231",
    "id": null,
    "metadata": {},
    "name": "TransportDocumentListQuery",
    "operationKind": "query",
    "text": "query TransportDocumentListQuery(\n  $search: String\n  $where: TransportDocumentWhere\n) {\n  ...TransportDocumentListFragment_1o2pVI\n}\n\nfragment TransportDocumentListFragment_1o2pVI on Query {\n  transportDocuments(first: 20, ordering: [{sort: ID, direction: DESC}], filter: {search: $search, where: $where}) {\n    edges {\n      cursor\n      node {\n        id\n        ...TransportDocumentListItem\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment TransportDocumentListItem on TransportDocument {\n  id\n  sendAt\n  signedAt\n  documentUrl\n  updatedAt\n  signingToken\n  shipment {\n    id\n    group {\n      name\n      note\n      id\n    }\n    customer {\n      name\n      id\n    }\n    supplier {\n      name\n      id\n    }\n    article {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f3ab24fc08bf38850494bce39ba079f5";

export default node;
