import * as React from 'react';
import { DependencyList } from 'react';
import {
  StyleSheet,
  View,
  useWindowDimensions,
  Linking,
  Platform,
  Keyboard,
} from 'react-native';
import { Checkbox } from 'react-native-paper';

import Appbar from './Appbar';

export const defaultStyles = StyleSheet.create({
  full: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  gutter: {
    margin: 16,
  },
  gutterTop: {
    marginTop: 16,
  },
  gutterBottom: {
    marginBottom: 16,
  },
  gutterLeft: {
    marginLeft: 16,
  },
  gutterRight: {
    marginRight: 16,
  },
  gutterHorizontal: {
    marginRight: 16,
    marginLeft: 16,
  },
});

export function useBigScreen() {
  const { width } = useWindowDimensions();
  return width > 1200;
}

export function dateFromUnix(n: number): Date {
  return new Date(n * 1000);
}

export function dateToUnixWithTimezoneOffset(d: Date): number {
  return Math.floor(d.getTime() / 1000) - d.getTimezoneOffset() * 60;
}

export function dateToUnix(d: Date): number {
  return Math.floor(d.getTime() / 1000);
}

export function startOfDate(d: Date) {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
}

export function endOfDate(d: Date) {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);
}

export function startOfDateUnix(d: Date) {
  return dateToUnix(startOfDate(d));
}

export function endOfDateUnix(d: Date) {
  return dateToUnix(endOfDate(d));
}

export function useModalState() {
  const [visible, setVisible] = React.useState(false);
  const dismiss = React.useCallback(() => {
    setVisible(false);
  }, [setVisible]);
  const show = React.useCallback(() => {
    setVisible(true);
  }, [setVisible]);
  return { visible, show, dismiss };
}

// https://github.com/relay-tools/relay-compiler-language-typescript/issues/64#issuecomment-511765083
export type NoRefs<T> = T extends Record<string, unknown>
  ? Omit<T, ' $refType' | ' $fragmentRefs'>
  : T;

export type ExtractRelayEdgeNode<
  T extends { edges: readonly ({ node: any | null } | null)[] | null } | null,
> = NoRefs<
  NonNullable<NonNullable<NonNullable<NonNullable<T>['edges']>[0]>['node']>
>;

export type ExtractRelayEdge<
  T extends { edges: readonly ({ node: any | null } | null)[] | null } | null,
> = NonNullable<NonNullable<T>['edges']>[0];

export function getNodes<
  T extends { edges: readonly ({ node: any | null } | null)[] | null } | null,
>(a: T): ExtractRelayEdgeNode<T>[] {
  return (a as any)?.edges
    ?.filter((e: any) => !!e?.node)
    .map((it: any) => it?.node!);
}

export function getRandomId() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export function htmlToText(text: string | undefined | null): string {
  return text?.replace(/<[^>]*>/g, '').replace(/\n/g, ' ') || '';
}

export function withoutEmptyEdges<
  T extends readonly ({ node: any | null } | null)[] | null,
>(a: T): T {
  return a?.filter((e) => !!e?.node) as any as T;
}

export function getCurrencySymbol(locale: string, currency: string) {
  const withCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
    .formatToParts(3.5)
    .map((val) => val.value)
    .join('');
  const withoutCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
    .formatToParts(3.5)
    .slice(1)
    .map((val) => val.value)
    .join('');
  return withCurrency.replace(withoutCurrency, '');
}
export function getFriendlyId(id: string): number {
  return Number(id?.split('-')[1]);
}

export function useSearch() {
  const [searchStateValue, setSearchStateValue] = React.useState('');
  const searchQuery = React.useDeferredValue<string>(searchStateValue);

  const onChangeSearch = (query: string) => {
    setSearchStateValue(query);
  };

  return {
    searchbarProps: { value: searchStateValue, onChangeText: onChangeSearch },
    search: searchQuery,
  };
}

export function getBackendUrl(path: string) {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:44300' + path;
  }
  return 'https://ddtradingapp.nl' + path;
}

export function getFrontendUrl(path: string) {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:19006' + path;
  }
  return 'https://ddtradingapp.nl' + path;
}

/**
 * Returns a `boolean` indicating whether the current update is the intial
 * render (when the component is first mounted).
 */
export function useInitialRender() {
  const isInitialRender = React.useRef(true);
  React.useEffect(() => {
    isInitialRender.current = false;
  }, []);
  return isInitialRender.current;
}

/**
 * Exactly like `useEffect`, except that the effect only executes on subsequent
 * updates after a component mounts.
 */
export function useEffectAfterMount(
  effect: React.EffectCallback,
  deps?: DependencyList,
) {
  const isInitialRender = useInitialRender();
  const savedCallback = React.useRef(effect);

  React.useEffect(() => {
    savedCallback.current = effect;
  }, [effect]);

  React.useEffect(() => {
    if (!isInitialRender) {
      return savedCallback.current();
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

const dayFormatter = new Intl.DateTimeFormat('nl', {
  timeStyle: 'short',
});

const weekFormatter = new Intl.DateTimeFormat('nl', {
  weekday: 'long',
});

export const monthFormatter = new Intl.DateTimeFormat('nl', {
  day: 'numeric',
  month: 'short',
});
const yearFormatter = new Intl.DateTimeFormat('nl', {
  day: 'numeric',
  month: 'short',
});
export const fullFormatter = new Intl.DateTimeFormat('nl', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
});

const friendlyWithoutYearWithTime = new Intl.DateTimeFormat('nl', {
  dateStyle: 'long',
  timeStyle: 'short',
});

const friendlyWithoutYearWithoutTime = new Intl.DateTimeFormat('nl', {
  day: 'numeric',
  month: 'long',
});

const friendlyWithTime = new Intl.DateTimeFormat('nl', {
  dateStyle: 'long',
  timeStyle: 'short',
});

const friendlyWithoutTime = new Intl.DateTimeFormat('nl', {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});

export function getFriendlyDate(date: Date, withTime = false) {
  const now = new Date();

  if (isSameYear(now, date)) {
    return withTime
      ? friendlyWithoutYearWithTime.format(date)
      : friendlyWithoutYearWithoutTime.format(date);
  }
  return withTime
    ? friendlyWithTime.format(date)
    : friendlyWithoutTime.format(date);
}

export function getFriendlyDateIntn(Date: Date) {
  return `${Date.getFullYear()}-${Date.getMonth() + 1}-${Date.getDate()}`;
}

export function getFriendlyShortDate(date: Date) {
  const now = new Date();
  if (isSameDay(now, date)) {
    return dayFormatter.format(date);
  } else if (isSameWeek(now, date)) {
    return weekFormatter.format(date);
  } else if (isSameMonth(now, date)) {
    return monthFormatter.format(date);
  } else if (isSameYear(now, date)) {
    return yearFormatter.format(date);
  }
  return fullFormatter.format(date);
}

function isSameDay(today: Date, date: Date) {
  return (
    today.getDate() === date.getDate() &&
    today.getMonth() === date.getMonth() &&
    today.getFullYear() === date.getFullYear()
  );
}

function isSameWeek(today: Date, date: Date) {
  const todayDate = today.getDate();
  const todayDay = today.getDay();

  // get first date of week
  const firstDayOfWeek = new Date(today.setDate(todayDate - todayDay));

  // get last date of week
  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  // if date is equal or within the first and last dates of the week
  return date >= firstDayOfWeek && date <= lastDayOfWeek;
}

function isSameMonth(today: Date, date: Date) {
  return (
    today.getMonth() === date.getMonth() &&
    today.getFullYear() === date.getFullYear()
  );
}

function isSameYear(today: Date, date: Date) {
  return today.getFullYear() === date.getFullYear();
}

type SelectItemsState = {
  selectedAll: boolean;
  selectedExclude: Record<string, boolean>;
  selectedInclude: Record<string, boolean>;
  selectedRenderKey: number;
};

export type SelectHookReturnType = ReturnType<typeof useSelectItems>;

export function useSelectItems({ total }: { total: number }) {
  const [state, setState] = React.useState<SelectItemsState>({
    // title: '',
    selectedAll: false,
    selectedExclude: {},
    selectedInclude: {},
    selectedRenderKey: 0,
  });

  const { selectedInclude, selectedExclude, selectedAll, selectedRenderKey } =
    state;
  const countExcludes = Object.keys(selectedExclude).length;
  const countIncludes = Object.keys(selectedInclude).length;
  const allSelected =
    (selectedAll && countExcludes === 0) ||
    (total !== 0 && countIncludes === total && countExcludes === 0);

  const onDeselectAll = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      rerenderKey: prevState.selectedRenderKey + 1,
      selectedInclude: {},
      selectedExclude: {},
      selectedAll: false,
    }));
  }, [setState]);

  const onSelectAll = React.useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      rerenderKey: prevState.selectedRenderKey + 1,
      selectedInclude: {},
      selectedExclude: {},
      selectedAll: !allSelected,
    }));
  }, [setState, allSelected]);

  const onSelect = React.useCallback(
    (itemId: string) => {
      const key: 'selectedExclude' | 'selectedInclude' = selectedAll
        ? 'selectedExclude'
        : 'selectedInclude';

      setState((prevState) => {
        const newSelected = { ...prevState[key] };
        if (newSelected[itemId]) {
          delete newSelected[itemId];
        } else {
          newSelected[itemId] = true;
        }
        return {
          ...prevState,
          [key]: newSelected,
          rerenderKey: prevState.selectedRenderKey + 1,
        };
      });
    },
    [selectedAll],
  );

  const isItemSelected = (itemId: string) => {
    let itemSelected = selectedInclude[itemId];
    if (selectedAll) {
      itemSelected = !selectedExclude[itemId];
    }
    return itemSelected;
  };

  let howManySelected = Object.keys(selectedInclude).length;

  if (selectedAll) {
    howManySelected = total - Object.keys(selectedExclude).length;
  }

  const isSelecting = howManySelected > 0;

  return {
    // countExcludes,
    // countIncludes,
    selectedInclude,
    selectedExclude,
    // selectedAll,
    selectedRenderKey,
    // handlers
    onSelectAll,
    onDeselectAll,
    onSelect,
    // data
    allSelected,
    isSelecting,
    howManySelected,

    // helper functions
    isItemSelected,
  };
}

export function SelectAllAppbar({
  selectState,
  children,
}: {
  selectState: SelectHookReturnType;
  children: any;
}) {
  return selectState.isSelecting ? (
    <View style={{ position: 'absolute', left: 0, right: 0, top: 6 }}>
      <Appbar style={{ elevation: 0, zIndex: 100 }}>
        <Appbar.BackAction onPress={selectState.onDeselectAll} />
        <Appbar.Content title={`${selectState.howManySelected}`} />
        {children}
      </Appbar>
    </View>
  ) : null;
}

export function SelectAllButton({
  selectState,
}: {
  selectState: SelectHookReturnType;
}) {
  return (
    <Checkbox.Android
      status={selectState.allSelected ? 'checked' : 'unchecked'}
      onPress={selectState.onSelectAll}
    />
  );
}

export function useLatest<T>(value: T): { readonly current: T } {
  const ref = React.useRef(value);
  ref.current = value;
  return ref;
}

export function assetsPath(path: string) {
  return `https://ddtrading-assets.s3.eu-central-1.amazonaws.com/${path}`;
}

export function shareViaWhatsapp(message: string) {
  Linking.openURL(
    `https://api.whatsapp.com/send/?text=${encodeURIComponent(message)}`,
  );
}

export function closeKeyboardAndRun(fn: () => void) {
  if (Platform.OS === 'web') {
    fn();
    return;
  }
  if (Keyboard.isVisible()) {
    const listener = Keyboard.addListener('keyboardDidHide', () => {
      listener.remove();
      fn();
    });
    Keyboard.dismiss();
  } else {
    fn();
  }
}

type DeepPartialObject<T> = {
  -readonly [P in keyof T]: T[P] extends object
    ? DeepPartialObject<T[P]> | null
    : T[P];
};

type FirstLevelPartial<T> = {
  -readonly [P in keyof T]?: T[P] extends object
    ? DeepPartialObject<T[P]> | null
    : T[P];
};

export type OptionalFormType<T> = FirstLevelPartial<T>;
