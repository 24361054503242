/**
 * @generated SignedSource<<f0ed64d32270e6b830df0d2156e30402>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShipmentWithStatusCustomerInvoiceStatus = "PENDING" | "SENT";
export type ShipmentWithStatusStatus = "CONCEPT" | "SENT";
export type ShipmentWithStatusSupplierInvoiceStatus = "PAID" | "UNPAID";
export type ShipmentWithStatusTransporterInvoiceStatus = "PAID" | "UNPAID";
export type ShipmentWithStatusWhere = {
  abcDelivery?: BooleanFilter | null;
  and?: ShipmentWithStatusWhere | null;
  articleId?: IDFilter | null;
  costPrice?: FloatFilter | null;
  createdAt?: TimeUnixFilter | null;
  customerCustomAddress?: StringFilter | null;
  customerCustomCity?: StringFilter | null;
  customerCustomCountry?: StringFilter | null;
  customerCustomName?: StringFilter | null;
  customerCustomPostalcode?: StringFilter | null;
  customerId?: IDFilter | null;
  customerInvoiceStatus?: ShipmentWithStatusCustomerInvoiceStatusFilter | null;
  customerInvoiceVismaId?: IDFilter | null;
  customerName?: StringFilter | null;
  customerWeight?: FloatFilter | null;
  deletedAt?: TimeUnixFilter | null;
  detailEmailSentToSupplier?: BooleanFilter | null;
  driverTelephone?: StringFilter | null;
  exclude?: BooleanFilter | null;
  expectedDeliveryDate?: TimeUnixFilter | null;
  groupId?: IDFilter | null;
  hasDifferentCustomerAddress?: BooleanFilter | null;
  hasDifferentSupplierAddress?: BooleanFilter | null;
  hasMarge?: BooleanFilter | null;
  id?: IDFilter | null;
  invoiceId?: IDFilter | null;
  licensePlate?: StringFilter | null;
  margeCostPriceEur?: FloatFilter | null;
  margeSellingPriceEur?: FloatFilter | null;
  or?: ShipmentWithStatusWhere | null;
  portalStatus?: StringFilter | null;
  potentialTransporterId?: IDFilter | null;
  privateComment?: StringFilter | null;
  proposalId?: IDFilter | null;
  publicComment?: StringFilter | null;
  risk?: IntFilter | null;
  sellingPrice?: FloatFilter | null;
  shipmentDate?: TimeUnixFilter | null;
  shipmentId?: IDFilter | null;
  status?: ShipmentWithStatusStatusFilter | null;
  supplierCustomAddress?: StringFilter | null;
  supplierCustomCity?: StringFilter | null;
  supplierCustomCountry?: StringFilter | null;
  supplierCustomName?: StringFilter | null;
  supplierCustomPostalcode?: StringFilter | null;
  supplierId?: IDFilter | null;
  supplierInvoiceStatus?: ShipmentWithStatusSupplierInvoiceStatusFilter | null;
  supplierName?: StringFilter | null;
  supplierWeight?: FloatFilter | null;
  transportationPrice?: FloatFilter | null;
  transporterId?: IDFilter | null;
  transporterInvoiceStatus?: ShipmentWithStatusTransporterInvoiceStatusFilter | null;
  transporterWeight?: FloatFilter | null;
  updatedAt?: TimeUnixFilter | null;
  weight?: FloatFilter | null;
  withDeleted?: boolean | null;
};
export type IDFilter = {
  equalTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isNull?: boolean | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notNull?: boolean | null;
};
export type StringFilter = {
  contain?: string | null;
  containStrict?: string | null;
  endWith?: string | null;
  endWithStrict?: string | null;
  equalTo?: string | null;
  in?: ReadonlyArray<string> | null;
  isEmpty?: boolean | null;
  isNull?: boolean | null;
  isNullOrEmpty?: boolean | null;
  notContain?: string | null;
  notContainStrict?: string | null;
  notEmpty?: boolean | null;
  notEndWith?: string | null;
  notEndWithStrict?: string | null;
  notEqualTo?: string | null;
  notIn?: ReadonlyArray<string> | null;
  notNull?: boolean | null;
  notNullOrEmpty?: boolean | null;
  notStartWith?: string | null;
  notStartWithStrict?: string | null;
  startWith?: string | null;
  startWithStrict?: string | null;
};
export type ShipmentWithStatusStatusFilter = {
  equalTo?: ShipmentWithStatusStatus | null;
  in?: ReadonlyArray<ShipmentWithStatusStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: ShipmentWithStatusStatus | null;
  notIn?: ReadonlyArray<ShipmentWithStatusStatus> | null;
  notNull?: boolean | null;
};
export type TimeUnixFilter = {
  equalTo?: number | null;
  isNull?: boolean | null;
  isNullOrZero?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notNull?: boolean | null;
  notNullOrZero?: boolean | null;
};
export type FloatFilter = {
  equalTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  isNullOrZero?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
  notNull?: boolean | null;
  notNullOrZero?: boolean | null;
};
export type ShipmentWithStatusCustomerInvoiceStatusFilter = {
  equalTo?: ShipmentWithStatusCustomerInvoiceStatus | null;
  in?: ReadonlyArray<ShipmentWithStatusCustomerInvoiceStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: ShipmentWithStatusCustomerInvoiceStatus | null;
  notIn?: ReadonlyArray<ShipmentWithStatusCustomerInvoiceStatus> | null;
  notNull?: boolean | null;
};
export type ShipmentWithStatusSupplierInvoiceStatusFilter = {
  equalTo?: ShipmentWithStatusSupplierInvoiceStatus | null;
  in?: ReadonlyArray<ShipmentWithStatusSupplierInvoiceStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: ShipmentWithStatusSupplierInvoiceStatus | null;
  notIn?: ReadonlyArray<ShipmentWithStatusSupplierInvoiceStatus> | null;
  notNull?: boolean | null;
};
export type ShipmentWithStatusTransporterInvoiceStatusFilter = {
  equalTo?: ShipmentWithStatusTransporterInvoiceStatus | null;
  in?: ReadonlyArray<ShipmentWithStatusTransporterInvoiceStatus> | null;
  isNull?: boolean | null;
  notEqualTo?: ShipmentWithStatusTransporterInvoiceStatus | null;
  notIn?: ReadonlyArray<ShipmentWithStatusTransporterInvoiceStatus> | null;
  notNull?: boolean | null;
};
export type BooleanFilter = {
  equalTo?: boolean | null;
  isNull?: boolean | null;
  notEqualTo?: boolean | null;
  notNull?: boolean | null;
};
export type IntFilter = {
  equalTo?: number | null;
  in?: ReadonlyArray<number> | null;
  isNull?: boolean | null;
  isNullOrZero?: boolean | null;
  lessThan?: number | null;
  lessThanOrEqualTo?: number | null;
  moreThan?: number | null;
  moreThanOrEqualTo?: number | null;
  notEqualTo?: number | null;
  notIn?: ReadonlyArray<number> | null;
  notNull?: boolean | null;
  notNullOrZero?: boolean | null;
};
export type ShipmentListPortalScreenPaginationQuery$variables = {
  after?: string | null;
  first?: number | null;
  search?: string | null;
  where?: ShipmentWithStatusWhere | null;
};
export type ShipmentListPortalScreenPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ShipmentListPortalScreenFragment">;
};
export type ShipmentListPortalScreenPaginationQuery = {
  response: ShipmentListPortalScreenPaginationQuery$data;
  variables: ShipmentListPortalScreenPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v5 = [
  (v1/*: any*/),
  {
    "fields": [
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "ordering",
    "value": [
      {
        "direction": "DESC",
        "sort": "ID"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentListPortalScreenPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ShipmentListPortalScreenFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentListPortalScreenPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ShipmentWithStatusConnection",
        "kind": "LinkedField",
        "name": "customShipments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShipmentWithStatusEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShipmentWithStatus",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supplierName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "transportationPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shipmentDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDifferentCustomerAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasDifferentSupplierAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supplierCustomAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supplierCustomPostalcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supplierCustomCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerCustomAddress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerCustomPostalcode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerCustomCity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerCustomName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supplierCustomName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerCustomCountry",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "supplierCustomCountry",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expectedDeliveryDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weight",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "licensePlate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portalStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "ordering",
          "filter"
        ],
        "handle": "connection",
        "key": "ShipmentListPortalScreen_customShipments",
        "kind": "LinkedHandle",
        "name": "customShipments"
      }
    ]
  },
  "params": {
    "cacheID": "a0a12ca16a8035a22ebedca8ed7c0065",
    "id": null,
    "metadata": {},
    "name": "ShipmentListPortalScreenPaginationQuery",
    "operationKind": "query",
    "text": "query ShipmentListPortalScreenPaginationQuery(\n  $after: String = null\n  $first: Int = 20\n  $search: String = null\n  $where: ShipmentWithStatusWhere = null\n) {\n  ...ShipmentListPortalScreenFragment_2dJq5R\n}\n\nfragment ShipmentListPortalItem on ShipmentWithStatus {\n  id\n  supplierName\n  transportationPrice\n  customerName\n  shipmentDate\n  hasDifferentCustomerAddress\n  hasDifferentSupplierAddress\n  supplierCustomAddress\n  supplierCustomPostalcode\n  supplierCustomCity\n  customerCustomAddress\n  customerCustomPostalcode\n  customerCustomCity\n  customerCustomName\n  supplierCustomName\n  customerCustomCountry\n  supplierCustomCountry\n  expectedDeliveryDate\n  weight\n  licensePlate\n  portalStatus\n}\n\nfragment ShipmentListPortalScreenFragment_2dJq5R on Query {\n  customShipments(first: $first, after: $after, ordering: [{sort: ID, direction: DESC}], filter: {search: $search, where: $where}) {\n    edges {\n      cursor\n      node {\n        id\n        ...ShipmentListPortalItem\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a331b9f26bfa35f58112b74bff2af994";

export default node;
