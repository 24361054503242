import * as React from 'react';
import { ComponentProps } from 'react';
import { StyleSheet } from 'react-native';
import { Appbar as NativeAppbar, useTheme } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useBigScreen } from './utils';

function PureAppbar({ style, ...rest }: ComponentProps<typeof NativeAppbar>) {
  const insets = useSafeAreaInsets();
  const bigScreen = useBigScreen();
  const theme = useTheme();
  const pureStyle = React.useMemo(() => {
    return [
      { backgroundColor: theme.colors.background, elevation: 0 },
      bigScreen && styles.bigScreenRoot,
      style,
    ];
  }, [bigScreen, theme.dark, style]);
  return (
    <NativeAppbar.Header
      dark={theme.dark}
      style={pureStyle}
      {...rest}
      statusBarHeight={insets.top}
    />
  );
}
const styles = StyleSheet.create({
  bigScreenRoot: {
    height: 72,
  },
});

type AppbarType = typeof PureAppbar & {
  Action: typeof NativeAppbar.Action;
  BackAction: typeof NativeAppbar.BackAction;
  Content: typeof NativeAppbar.Content;
};
const Appbar = React.memo(PureAppbar) as unknown as AppbarType;
Appbar.Action = NativeAppbar.Action;
Appbar.BackAction = NativeAppbar.BackAction;
Appbar.Content = NativeAppbar.Content;
export default Appbar;
