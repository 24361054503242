import { graphql } from 'react-relay';
import * as relayHooks from 'react-relay/hooks';
import { readInlineData } from 'relay-runtime';

import authState, { emptyAuthState } from './AuthState';
import environment from '../RelayEnvironment';
import { AuthorizationUtilsQuery } from '../__generated__/AuthorizationUtilsQuery.graphql';
import { AuthorizationUtilsUser$key } from '../__generated__/AuthorizationUtilsUser.graphql';

export const authorizationUtilsUserFragment = graphql`
  fragment AuthorizationUtilsUser on User @inline {
    id
    email
    role

    supplier {
      id
      name
      currency
      language
    }
    customer {
      id
    }
  }
`;

const query = graphql`
  query AuthorizationUtilsQuery {
    profile {
      user {
        ...AuthorizationUtilsUser
      }
    }
  }
`;

export function fetchAndSaveProfileForToken({
  token,
  onCompleted,
  onError,
}: {
  token: string;
  onCompleted?: () => any;
  onError?: (error: Error) => any;
}) {
  // first we set the token in memory so we can try to request the profile with this token
  authState.set({
    token,
    user: null,
    resolving: true,
  });

  relayHooks
    .fetchQuery<AuthorizationUtilsQuery>(environment, query, {})
    .subscribe({
      start: () => {
        console.log('START');
      },
      complete: () => {
        console.log('complete');
      },
      error: (error: Error) => {
        console.log('fetchAndSaveProfileForToken ERROR', { error });
        console.log({ error });
        authState.set(emptyAuthState);
        authState.set({
          token,
          user: null,
          resolving: false,
        });
        onError && onError(error);
      },
      next: (data) => {
        const user = readInlineData<AuthorizationUtilsUser$key>(
          authorizationUtilsUserFragment as any,
          data.profile.user,
        );

        authState.set({
          token,
          user,
          resolving: false,
        });

        onCompleted && onCompleted();
      },
    });
}
